export const addbeautysalonusersSrv = (param) => {
  async function addbeautysalonusersService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/addbeautysalonusers', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        salonName: param.salon_name,
        code: param.code,
        role: param.role,
        email: param.email
      }),
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return addbeautysalonusersService()
}
