export const updatebeautysalonclientsSrv = (param) => {
  async function updatebeautysalonclientsService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/updatebeautysalonclients/' + param.case_id, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({                
        sum: param.sum,
        otherData: param?.other_data ? param?.other_data : null,
      }),
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return updatebeautysalonclientsService()
}
