import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import CancelSharpIcon from '@material-ui/icons/Close';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import BorderInnerIcon from '@material-ui/icons/BorderInner';
import { styled } from '@material-ui/core/styles';
import { makeStyles} from '@material-ui/core/styles';
import { themeHlp }  from '../Helper/ThemeHelper.js'
import  appResizeWindowHlp  from '../Helper/AppResizeWindowHelper.js';
import { getfilesSrv } from '../Service/GetFilesService.js';
import { getsharedfilesSrv } from '../Service/GetSharedFilesService.js';
import { exportfilesSrv } from '../Service/ExportFilesService.js';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: themeHlp().palette.blackText.light,
    backgroundColor: themeHlp().palette.secondary.light,  
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        color: themeHlp().palette.blackText.main,        
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  menu_box:{
    width:'100%', 
    height:'340px', 
    margin:'auto auto', 
    background: 'linear-gradient(135deg, rgb(38, 93, 140) 0%, rgb(38, 93, 140) 15%, rgb(0, 140, 183) 50%)', 
    opacity:'1', 
    borderRadius:'15px', 
    border:'4px solid #f5a33e'
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: themeHlp().palette.secondary.light,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  user_avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: 'auto',
    cursor: 'pointer'
  },
  dialog_action:{
    padding: '0px',
  },
  dialog_content: {
    padding: '0px',
  },
  zoom_percentage: {
    position: 'absolute', 
    top: '10px', 
    left: '10px', 
    width: '64px',
    padding: '20px',
    backgroundColor: themeHlp().palette.secondary.light,
    color: themeHlp().palette.blackText.main,
    textAlign: 'center',
    border: '1px solid ' + themeHlp().palette.blackText.main,
    borderRadius: '15px',
    opacity: '0.5'
  },
  param_box: {
    width:'64px',    
    backgroundColor: 'white',
    border: '1px solid ' + themeHlp().palette.blackText.light,        
    margin: '0 2px 0 6px',
    '&:hover': {
        border: '1px solid ' + themeHlp().palette.primary.light,        
    }
  },
  iconbutton: {
    width:'36px',
    height: '36px',
    color: themeHlp().palette.primary.light,
    '&:hover' : {
      color: themeHlp().palette.primary.main
    },
    '&:active' : {
      color: themeHlp().palette.primary.dark,    
    }
  },
  file_icon: {
    width: '24px',
    height: '24px',     
    backgroundRepeat: 'no-repeat',
    backgroundSize: '22px',
    backgroundPosition: '0px 2px',
    padding: '0 24px 0 0',
  },
  upload_file: {
    display: 'none'
  },
  process_dialog_content: { 
    overflow: 'hidden', 
    border: `1px solid ${ themeHlp().palette.greyText.light}`, 
    borderStyle:'solid none solid none', 
    backgroundColor: themeHlp().palette.secondary.light,
    '&.MuiDialogContent-root':{
      flex: 'none'
    }
  },
  process_messages_box: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    flexWrap: 'wrap',
    maxWidth: '1800px'
  },
  process_messages_effect:  {
    position: 'relative',
    padding: '0px',
    overflow: 'hidden',
  },
  linear_progress_box: {
    width: '200px',
    margin: 'auto auto',
    '& .MuiLinearProgress-root': {
      backgroundColor: themeHlp().palette.secondary.light
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: themeHlp().palette.primary.light
    }   
  },
}));

let timeoutAlert = {};

const ImageViewerApp = (props) => {
  const classes = useStyles();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const zoomResetRef = useRef();
  const [open, setOpen] = React.useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [imgWidth, setImgWidth] = useState(window.innerWidth);
  const [imgHeight, setImgHeight] = useState(window.innerHeight);
  const [resizeWidth, setResizeWidth] = useState(0);
  const [resizeHeight, setResizeHeight] = useState(0);
  const [quality, setQuality] = useState(100);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [flipH, setFlipH] = useState(1);
  const [flipV, setFlipV] = useState(1);
  const [fileUploadStarted, setFileUploadStarted] = useState(false);  
  const [toolbarHidden, setToolbarHidden] = useState(false);
  const [responseType, setResponseType] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [responseTime, setResponseTime] = useState(4);
  const titleDisplaySize = 588;
  let imagePlace = document.getElementById('imagePlace');

  function defineImageParams(){    
    let imagePlace = document.getElementById('imagePlace');
    if((imagePlace && ((resizeWidth === 0 || resizeHeight === 0 || isNaN(resizeWidth) || isNaN(resizeHeight)) && fileUploadStarted === false)) || fileUploadStarted === true){
      setResizeWidth(imagePlace.naturalWidth); 
      setResizeHeight(imagePlace.naturalHeight);    
    }
  }

  const [anchorZoomEl, setAnchorZoomEl] = React.useState(null);
  const openZoom = Boolean(anchorZoomEl);
  const openZoomMenu = (event) => {
    setAnchorZoomEl(event.currentTarget);
  };
  const closeZoomMenu = () => {
    setAnchorZoomEl(null);
  };

  const [anchorFlipRotateEl, setAnchorFlipRotateEl] = React.useState(null);
  const openFlipRotate = Boolean(anchorFlipRotateEl);
  const openFlipRotateMenu = (event) => {
    setAnchorFlipRotateEl(event.currentTarget);
  };
  const closeFlipRotateMenu = () => {
    setAnchorFlipRotateEl(null);
  };

  const [anchorResizeEl, setAnchorResizeEl] = React.useState(null);
  const openResize = Boolean(anchorResizeEl);
  const openResizeMenu = (event) => {
    setAnchorResizeEl(event.currentTarget);
    defineImageParams();
  };
  const closeResizeMenu = () => {
    setAnchorResizeEl(null);
  };

  const [anchorExportEl, setAnchorExportEl] = React.useState(null);
  const openExport = Boolean(anchorExportEl);
  const openExportMenu = (event) => {
    setAnchorExportEl(event.currentTarget);
    defineImageParams();
  };
  const closeExportMenu = () => {
    setAnchorExportEl(null);
  };
  
  const handleClickOpen = () => {


    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('imageviewer') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount <2){      
      allApps.push('imageviewer');
      setOpen(true);
      setFileUploadStarted(false);
      setImgSrc(null);
      if(props.fileInfo?.shared_with){
        const getsharedfilesParams = {
          item_id: props.fileInfo?.item_id
        }

        getsharedfilesSrv(getsharedfilesParams)
        .then(async response => {
          setImgSrc(response.data);
        }).catch(err => {
          console.log(err);
        //  history.push("/desktop");
        })
      }else{
        const getfilesParams = {
          item_id: props.fileInfo?.item_id
        }
        if(getfilesParams.item_id !== undefined){
          getfilesSrv(getfilesParams)
          .then(async response => {
            setImgSrc(response.data);
          }).catch(err => {
            console.log(err);
          //  history.push("/desktop");
          })
        }
      }
      
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
        apps: allApps,
        appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount + 1
      }));    
      
      setTimeout(() => {
        appsInWindows('load');
      },100);
    }
  };

  const handleClose = () => {
    setOpen(false);
    if(props.appCloses){
      props.appCloses(true);
    }
    //props.changeAppStatus(false)

    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;
    if(allApps.indexOf('imageviewer') !== -1){
      allApps.splice(allApps.indexOf('imageviewer'), 1);
    }

    localStorage.setItem('movwin', JSON.stringify({
      windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
      apps: allApps,
      appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount - 1
    }));
  };

  const appsInWindows = (event) => {
    if(event === 'change'){
      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode < 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode + 1,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }else if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode === 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: 0,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }

      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).appCount === 0){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: 1
        }));
      }
    }

    setWindowWidth(windowWidth - 1);
    appResizeWindowHlp('imageViewerDialogBox');
  }

  const uploadFileFnc = (event) => {
    const file = event.target.files[0];
    setResponseType('');
    setResponseMessage('');    
    if(
      file?.type === 'image/avif' || //avif
      file?.type === 'image/vnd.microsoft.icon' || //ico
      file?.type === 'image/x-win-bitmap' || //cur
      file?.type === 'image/bmp' || //bmp
      file?.type === 'image/gif' || //gif
      file?.type === 'image/png' || //png
      file?.type === 'image/jpeg' || //jpg, jpeg
      file?.type === 'image/svg+xml' || //svg
      file?.type === 'image/webp' //webp
    ){
      defineImageParams();
      setFileUploadStarted(true);
      setImgSrc(null);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const uploaded_media = reader.result;
        setImgSrc(uploaded_media);
      });      
      reader.readAsDataURL(file);
    }else{
      if(file?.type !== undefined){      
        setResponseTime(responseTime + 1);
        setResponseType('error');
        setResponseMessage('File format "' + file?.type + '" not supported.');
      }
    }
  }

  const debounceImageSizeFnc = () => {

    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);

    if(imagePlace && (imagePlace.naturalWidth >= imagePlace.naturalHeight) && (windowWidth >=  windowHeight * (imagePlace.naturalWidth/imagePlace.naturalHeight))){
      //console.log(1.1 + '-------------')
      setImgWidth('auto');
      setImgHeight(((windowHeight *scale)-112) + 'px');
    }else if(imagePlace && (imagePlace.naturalWidth < imagePlace.naturalHeight) && (windowWidth * (imagePlace.naturalHeight/imagePlace.naturalWidth) >=  windowHeight)){
      //console.log(1.2 + '-------------')    
      setImgWidth('auto');
      setImgHeight(((windowHeight *scale)-112) + 'px');
    }else if(imagePlace && (imagePlace.naturalWidth >= imagePlace.naturalHeight) && ((windowWidth / (imagePlace.naturalWidth/imagePlace.naturalHeight )) <  windowHeight)){
      //console.log(2.1 + '-------------')
      setImgWidth(((windowWidth * scale)-20) + 'px');
      setImgHeight('auto');
    }else if(imagePlace && (imagePlace.naturalWidth < imagePlace.naturalHeight) && (windowWidth * (imagePlace.naturalHeight/imagePlace.naturalWidth) <  windowHeight)){
      //console.log(2.2 + '-------------')
      setImgWidth(((windowWidth * scale)-20) + 'px');
      setImgHeight('auto');
    }else if(!imagePlace && ((windowWidth) >= windowHeight)){
      //console.log(3);
      setImgWidth('auto');
      setImgHeight((windowHeight - 130) + 'px');
    }else if(!imagePlace && ((windowWidth ) < windowHeight)){
      //console.log(4);
      setImgWidth((windowWidth - 20) + 'px');
      setImgHeight('auto');
    }  
  }

  const zoomReset = () => {
    setScale(1);
  }

  const zoomIn = () => {
    if(scale < 4.9){
      setScale(scale + 0.1);
    }
  }

  const zoomOut = () => {
    if(scale > 0.2){
      setScale(scale - 0.1);
    }
  }

  const rotateAnticlockwise = () => {
    setRotate(rotate - 90);
  }

  const rotateClockwise = () => {
    setRotate(rotate + 90);
  }

  const flipHorizontally = () => {
    flipH === 1 ? setFlipH(-1) : setFlipH(1);
  }

  const flipVertically = () => {
    flipV === 1 ? setFlipV(-1) : setFlipV(1);
  }

  const resizeImageByWidth = (event) => {
    let width = event.target.value
    let imagePlace = document.getElementById('imagePlace');
    setResizeWidth(parseInt(width));
    setResizeHeight(parseInt(((imagePlace.naturalHeight/ imagePlace.naturalWidth) * width).toFixed(0)));
  }

  const resizeImageByHeight = (event) => {    
    let height = event.target.value
    let imagePlace = document.getElementById('imagePlace');
    setResizeHeight(parseInt(height));
    setResizeWidth(parseInt((height / (imagePlace.naturalHeight / imagePlace.naturalWidth)).toFixed(0)));
  }

  const goToExportFiles = (fileExtension) => {

    const exportfilesParams = {
      item_id: props.fileInfo?.item_id,
      item_location: window.location.pathname,
      item_ext: fileExtension,
      options: {
        resize: [resizeWidth, resizeHeight],
        rotate: rotate,
        flip: [flipH === 1 ? false : true, flipV === 1 ? false : true],
        quality: parseInt(quality)
      }
    }
    exportfilesSrv(exportfilesParams)
    .then(response => {
      if(response && response.statusCode === 201){
        window.location.reload();
      }    
    }).catch(err => {
      console.log(err);
    })
  }

  const AlertBox = () => {
    clearTimeout(timeoutAlert);
    if(responseType === 'error'){
      timeoutAlert = setTimeout(() => { setResponseType('errorFinished') }, 4000);
    }else if(responseType === 'report'){
      timeoutAlert = setTimeout(() => { setResponseType('') }, 4000);
    }

    if(responseType === 'warning'){
      return (
        <DialogContent className={classes.process_dialog_content}>
          <Box className={classes.process_messages_box}>          
            <div className={classes.process_messages_effect}>
              <div style={{
                position: 'absolute',
                inset: '0',
                background: themeHlp().palette.secondary.light,
                borderLeft: '2px solid ' + themeHlp().palette.secondary.light,
                borderRadius: '10px',
                top:'6px',
                height: '14px',
                transform: 'translateX(0%)',
                animation:  'translateOverlay 0.4s forwards cubic-bezier(0.03, 0, 0.15, 1)',
              }}></div>
              <Avatar component="span" alt="Warning" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/warning.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} /> 
              <Typography variant="subtitle1" component="span" style={{ cursor: 'default'}}>{responseMessage}</Typography>
            </div>
          </Box>
        </DialogContent>
      )
    }else if(responseType === 'error'){
      return (
        <DialogContent className={classes.process_dialog_content}>
          <Box className={classes.process_messages_box}>          
            <div className={classes.process_messages_effect}>
              <div style={{
                position: 'absolute',
                inset: '0',
                background: themeHlp().palette.secondary.light,
                borderLeft: '2px solid ' + themeHlp().palette.secondary.light,
                borderRadius: '10px',
                top:'6px',
                height: '14px',
                transform: 'translateX(0%)',
                animation:  'translateOverlay 0.4s forwards cubic-bezier(0.03, 0, 0.15, 1)',
              }}></div>
              <Avatar component="span" alt="Error" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/error.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} /> 
              <Typography variant="subtitle1" component="span" style={{ cursor: 'default'}}>{responseMessage}</Typography>
            </div>
          </Box>
        </DialogContent>
      )
    }else if(responseType === 'report'){
      return (
        <DialogContent className={classes.process_dialog_content}>
          <Box className={classes.process_messages_box}>
            <div className={classes.process_messages_effect}>
              <div style={{
                position: 'absolute',
                inset: '0',
                background: themeHlp().palette.secondary.light,
                borderLeft: '2px solid ' + themeHlp().palette.secondary.light,
                borderRadius: '10px',
                top:'6px',
                height: '14px',
                transform: 'translateX(0%)',
                animation:  'translateOverlay 0.4s forwards cubic-bezier(0.03, 0, 0.15, 1)',
              }}></div>
              <Avatar component="span" alt="Report" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/report.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} /> 
              <Typography variant="subtitle1" component="span" style={{ cursor: 'default'}}>{responseMessage}</Typography>
            </div>
          </Box>
        </DialogContent>
      )
    }
  }

  useLayoutEffect(() => {
    function updateSize() {
      debounceImageSizeFnc();
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => {     
      window.removeEventListener('resize', updateSize); 
    }
  }, [scale, windowWidth, windowHeight]);

  useEffect(() => {
    handleClickOpen();

    if(!localStorage.getItem('movwin')){
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: 0,
        apps: [],
        appCount: 1
      }));
    }     
    
    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('imageviewer') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount < 2){
      setOpen(true);
      setTimeout(() => {
        const muiBackdrop = document.querySelectorAll('.MuiBackdrop-root');
        let newApp = JSON.parse(localStorage.getItem('movwin')).apps;
        newApp.push('imageviewer');        
        if(muiBackdrop[2]){
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 2
          })); 
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1]) {          
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 1
          }));        
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1] === undefined) {        
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: [],
            appCount: 0,          
          }));        
        }
        appsInWindows('load');      
      },100);
    }
  },[])

return (
  <>
    <Avatar title="Image Viewer" component="span" variant="rounded" alt="Image Viewer" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/imageviewer.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={handleClickOpen} />
    <Typography title="Image Viewer" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', textOverflow: 'ellipsis'}} gutterBottom>Image Viewer</Typography>
    <Dialog id="imageViewerDialogBox" fullScreen onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        style={{
          padding: '8px 16px',
          color:'#ffffff', 
          background:'linear-gradient(90deg, rgba(38,93,140,1) 0%, rgba(38,93,140,1) 15%, rgba(0,140,183,1) 100%)'
        }}
        id="customized-dialog-title" onClose={handleClose}
      >
        <Avatar component="span" variant="rounded" style={{width:'24px', height: '24px', display: 'inline-block', marginRight: '8px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/imageviewer.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
        <span>{(windowWidth < titleDisplaySize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0) || (windowWidth < (titleDisplaySize/2) && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) === 0) ? '' : 'Image Viewer'}</span>
        <IconButton
            disabled={document.getElementById('shutdownDialog') ? false : true}
            aria-label="AppsInWindowsIcon"
            onClick={() => appsInWindows('change')}
            size="small"
            style={{
              background: themeHlp().palette.blueGrey.dark,
              color:'white',          
              margin:'2px 42px 0 0',
              padding:'2px',
              position: 'absolute',
              right: '0px',
              opacity: document.getElementById('shutdownDialog') ? '1' : '0.3'
            }}
          >
            <BorderInnerIcon style={{padding: '2px'}} />          
          </IconButton>
          <IconButton 
            aria-label="UnfoldLessMoreIcon"
            title={!toolbarHidden ? 'Hide Toolbar' : 'Show Toolbar'}
            onClick={()=> setToolbarHidden(current => !current)}
            size="small"
            style={{
              width: '18px',
              height: '18px',
              background: themeHlp().palette.blueGrey.dark,
              color:'white',  
              margin:'2px 74px 0 0',
              padding:'2px',
              position: 'absolute',
              right: '0px'
            }}
          >
            {!toolbarHidden ? <UnfoldLessIcon style={{padding: '4px'}} /> : <UnfoldMoreIcon style={{padding: '4px'}} />}
          </IconButton>
          <IconButton 
            aria-label="CancelSharpIcon"
            title="Close"
            onClick={handleClose}
            size="small"
            style={{
              background: themeHlp().palette.blueGrey.dark,
              color:'white', 
              margin:'2px 10px 0 0',
              padding:'2px',
              position: 'absolute',
              right: '0px'
            }}
          >
            <CancelSharpIcon style={{padding: '2px'}} /> 
          </IconButton>
   
      </DialogTitle>
      {!toolbarHidden && 
      <DialogActions id="p0" className={classes.dialog_action}>
        <div id="p1" className={classes.root}>
          <input className={classes.upload_file} id="contained-button-file-image-browse" type="file" accept="image/*" onChange={uploadFileFnc} />
          <List style={{ width: '100%', height: '64px', padding: '0px', overflowY: 'auto'}}>
            <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label htmlFor="contained-button-file-image-browse">
                <Avatar title="Import Image" component="span" variant="rounded" alt="Import Image" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_import_img.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
              </label>             
            </ListItem>
            {(imgSrc !== null && (responseType !== 'error' && responseType !== 'errorFinished')) &&
            <>
              <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
                <label>
                  <Avatar title="Zoom" component="span" variant="rounded" alt="Zoom" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_zoom_reset.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={openZoomMenu}/>
                </label>
              </ListItem>
              <div>
                <StyledMenu
                  id="image-zoom-menu"
                  MenuListProps={{
                    'aria-labelledby': 'image-zoom-menu',
                  }}
                  anchorEl={anchorZoomEl}
                  open={openZoom}
                  onClose={closeZoomMenu}
                >
                  <MenuItem ref={zoomResetRef} onClick={zoomReset}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_zoom_reset.png)'}}></span>
                    Zoom Reset
                  </MenuItem>
                  <MenuItem onClick={zoomIn}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_zoom_in.png)'}}></span>
                    Zoom In
                  </MenuItem>
                  <MenuItem onClick={zoomOut}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_zoom_out.png)'}}></span>
                    Zoom Out
                  </MenuItem>
                </StyledMenu>
              </div>
              <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
                <label>
                  <Avatar title="Flip and Rotate" component="span" variant="rounded" alt="Flip and Rotate" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_flip_and_rotate.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={openFlipRotateMenu}/>
                </label>
              </ListItem>
              <div>
                <StyledMenu
                  id="image-flip-and-rotate-menu"
                  MenuListProps={{
                    'aria-labelledby': 'image-flip-and-rotate-menu',
                  }}
                  anchorEl={anchorFlipRotateEl}
                  open={openFlipRotate}
                  onClose={closeFlipRotateMenu}
                >
                  <MenuItem onClick={rotateAnticlockwise}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_rotate_anticlockwise.png)'}}></span>
                    Rotate Anticlockwise
                  </MenuItem>
                  <MenuItem onClick={rotateClockwise}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_rotate_clockwise.png)'}}></span>
                    Rotate Clockwise
                  </MenuItem>
                  <MenuItem onClick={flipHorizontally}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_flip_horizontally.png)'}}></span>
                    Flip Horizontally
                  </MenuItem>
                  <MenuItem onClick={flipVertically}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_flip_vertically.png)'}}></span>
                    Flip Vertically
                  </MenuItem>
                </StyledMenu>
              </div>
              <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
                <label>
                  <Avatar title="Resize" component="span" variant="rounded" alt="Resize" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_resize.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={openResizeMenu}/>
                </label>
              </ListItem>
              <div>
                <StyledMenu
                  id="image-resize-menu"
                  MenuListProps={{
                    'aria-labelledby': 'image-resize-menu',
                  }}
                  anchorEl={anchorResizeEl}
                  open={openResize}
                  onClose={closeResizeMenu}
                >
                  <MenuItem style={{ pointerEvents: 'none' }}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_image_size.png)'}}></span>
                    Image Size             
                    <TextField                    
                      type="number"   
                      title="Width"                   
                      size="small"
                      variant="standard"
                      inputProps={{
                        min: "0",
                        step: "1"
                      }}
                      className={classes.param_box}
                      value={resizeWidth}
                      disabled={fileUploadStarted ? true : false}
                      onChange={(evn) => {resizeImageByWidth(evn)}}
                      style={{ pointerEvents: 'auto', }}
                    />
                    px            
                    <TextField
                      type="number"
                      title="Height"
                      size="small"
                      variant="standard"
                      inputProps={{
                        min: "0",
                        step: "1"
                      }}
                      className={classes.param_box}
                      value={resizeHeight}
                      disabled={fileUploadStarted ? true : false}
                      onChange={(evn) => resizeImageByHeight(evn)}
                      style={{ pointerEvents: 'auto' }}
                    />
                    px                
                  </MenuItem>
                  <MenuItem style={{ pointerEvents: 'none' }}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_quality.png)'}}></span>
                    Quality
                    <TextField
                      type="number"
                      size="small"
                      variant="standard"
                      inputProps={{
                        min: "0",
                        max: "100",
                        step: "10"
                      }}
                      className={classes.param_box}
                      value={quality}
                      disabled={fileUploadStarted ? true : false}
                      onChange={(e) => setQuality(e.target.value)}
                      style={{ pointerEvents: 'auto', width: '56px' }}
                    />
                    %
                  </MenuItem>
                </StyledMenu>
              </div>
              {fileUploadStarted === false &&
              <>
                <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
                  <label>
                    <Avatar title="Export as" component="span" variant="rounded" alt="Export as" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={openExportMenu} />
                  </label>                    
                </ListItem>
                <div>
                  <StyledMenu
                    id="image-export-menu"
                    MenuListProps={{
                      'aria-labelledby': 'image-export-menu',
                    }}
                    anchorEl={anchorExportEl}
                    open={openExport}
                    onClose={closeExportMenu}
                  >
                    <MenuItem onClick={() => goToExportFiles('jpeg')}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_jpeg.png)'}}></span>
                      Export as JPEG
                    </MenuItem>
                    <MenuItem onClick={() => goToExportFiles('png')}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_png.png)'}}></span>
                      Export as PNG
                    </MenuItem>
                    <MenuItem onClick={() => goToExportFiles('bmp')}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_bmp.png)'}}></span>
                      Export as BMP
                    </MenuItem>
                  </StyledMenu>
                </div>
              </>
              }
            </>
            }
          </List>
        </div>
      </DialogActions>
      }
      {(responseType !== '' && responseType !== 'errorFinished') &&  <AlertBox />}
      <DialogContent className={classes.dialog_content}>
        {(fileUploadStarted && imgSrc === null && responseMessage === '') &&
          <>
            <Typography variant="subtitle2" component="div" style={{fontSize: '14px', marginTop: '20px', color: themeHlp().palette.primary.main, /*padding: '0 10px',*/ borderRadius: '10px', textAlign: 'center'}}>
              Opening...
            </Typography>
            <Box className={classes.linear_progress_box}>
              <LinearProgress value={10}/>
            </Box>
          </>
        }      
        {typeof(imgSrc) === 'string' && responseMessage === '' &&
          <div style={{position: 'relative', height:'100%',  textAlign: 'center', padding: 0}}>
            <img id="imagePlace" alt="Image Viewer" src={imgSrc} 
              style={{
                width:`${imgWidth}`, 
                height:`${imgHeight}`,
                marginTop: '10px',
                transform: `rotate(${rotate}deg) scaleX(${flipH}) scaleY(${flipV})`
              }} />
            <div className={classes.zoom_percentage}>{parseInt((scale/1) * 100)}%</div>
          </div>
        }
      </DialogContent>
    </Dialog>
  </>
);
}
 
export default ImageViewerApp;
