export const getbeautysalonclientsSrv = (param) => {
  async function getbeautysalonclientsService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/getbeautysalonclients', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        page: param?.page ? param?.page : null,
        clientId: param?.client_id ? param?.client_id : null,
        userId: param?.user_id ? param?.user_id : null,
        keyword: param?.keyword ? param?.keyword : '',
        callType: param?.call_type
      }),
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return getbeautysalonclientsService()
}
