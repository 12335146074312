export const getlinksSrv = async (param) => {
  const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/getlinks/' + param.item_id, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  //   mode: 'no-cors', // no-cors, *cors, same-origin
  //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "include", // include, *same-origin, omit
  //   body: JSON.stringify(param)
  //  body: JSON.stringify({caseId: param.item_id, name: param.name, location: param.item_location})
  });
    return await rawResponse.json();
}
