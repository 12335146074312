import React, { useState } from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Link from '@material-ui/core/Link';
import { makeStyles} from '@material-ui/core/styles';
import AccountApp from '../Apps/AccountApp';
import FileFinderApp from '../Apps/FileFinderApp';
import ContactApp from '../Apps/ContactApp';
import ImageViewerApp from '../Apps/ImageViewerApp';
import MediaPlayerApp from '../Apps/MediaPlayerApp';
import DocumentReaderApp from '../Apps/DocumentReaderApp';
import ArchiveReaderApp from '../Apps/ArchiveReaderApp';
import CodeBuilderApp from '../Apps/CodeBuilderApp';
import NotesApp from '../Apps/NotesApp';
import LinkStorageApp from '../Apps/LinkStorageApp';
import BeautySalonAppointmentApp from '../Apps/BeautySalonAppointmentApp';
import { themeHlp } from '../Helper/ThemeHelper.js';
import { Divider } from '@material-ui/core';

export const ContactAppContext = React.createContext()

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 0,
    border: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  upload_file: {
    display: 'none'
  },
  menu_background: {
    background: themeHlp().palette.secondary.gradient
  },
  file_icon: {
    width: '24px',
    height: '24px',     
    backgroundRepeat: 'no-repeat',
    backgroundSize: '22px',
    backgroundPosition: '0px 2px',
    padding: '0px',
  }
}));

const SideBar = (props) => {

  const classes = useStyles();
  let navigate = useNavigate();
  let location = useLocation();
  let open = false;
  const [anchorElStrMenu, setAnchorElStrMenu] = useState(null);
  const openStrMenu = Boolean(anchorElStrMenu);
  const [opendialog, setOpendialog] = React.useState(false);
  const [folderStr, setFolderStr] = useState([]);
  const [accountAppOn, setAccountAppOn] = useState(false);
  const [fileFinderAppOn, setFileFinderAppOn] = useState(false);
  const [contactAppOn, setContactAppOn] = useState(false);
  const [imageViewerAppOn, setImageViewerAppOn] = useState(false);
  const [mediaPlayerAppOn, setMediaPlayerAppOn] = useState(false);
  const [documentReaderAppOn, setDocumentReaderAppOn] = useState(false);
  const [archiveReaderAppOn, setArchiveReaderAppOn] = useState(false);
  const [codeBuilderAppOn, setCodeBuilderAppOn] = useState(false);
  const [notesAppOn, setNotesAppOn] = useState(false);
  const [linkStorageAppOn, setLinkStorageAppOn] = useState(false);
  const [beautySalonAppointmentAppOn, setBeautySalonAppointmentAppOn] = useState(false);

  const handleClickOpen = () => {
    setOpendialog(true);
  };

  const handleClose = () => {
    setAccountAppOn(false);
    setFileFinderAppOn(false);
    setContactAppOn(false);
    setImageViewerAppOn(false);
    setMediaPlayerAppOn(false);
    setDocumentReaderAppOn(false);
    setArchiveReaderAppOn(false);
    setCodeBuilderAppOn(false);
    setNotesAppOn(false);
    setLinkStorageAppOn(false);
    setBeautySalonAppointmentAppOn(false);
    setOpendialog(false);

    if(localStorage.getItem('movwin')){
      localStorage.setItem('movwin', JSON.stringify({
       // windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
        windowMode: 0,
        apps: [],
        appCount: 0
      }));
    }


  };

  const goToFolder = (event) => {
    const locationPathname= decodeURI(location.pathname).split('/');
    locationPathname.shift();
    const folderStrArr = [{path: '/desktop', name: 'desktop'}];  
    for(let i=0; i<locationPathname.length - 1; i++){
      if(folderStrArr[i]){
        folderStrArr.push({path: folderStrArr[i].path + '/' + locationPathname[i+1], name: locationPathname[i+1]});
      }
    }
    setFolderStr(folderStrArr);
    setAnchorElStrMenu(event.currentTarget);
  }
  
  const goToFolderStrMenu = (value) => {
    setAnchorElStrMenu(null);
    if(typeof(value) === 'string'){
      navigate(value);
    }    
  }

  const createFile = (val) => {
    props.builderFile(val);
  }
  
  const uploadFileFnc = (event) => {
    if(event.target.files[0] !== undefined){     
      props.builderFile(event.target.files[0]);
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >     
        <List style={{marginTop:"48px", height:'100%', background:"linear-gradient(180deg, rgba(38,93,140,1) 0%, rgba(38,93,140,1) 15%, rgba(0,140,183,1) 100%"}}>
          <ListItem button  style={{padding:"8px"}}>            
          </ListItem>
          <ListItem button style={{padding:"8px"}}>
            <ListItemAvatar>
              <Avatar title="Show Applications" component="span" variant="rounded" alt="Menu" style={{color:'transparent',backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/menu.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={handleClickOpen} />
            </ListItemAvatar>
          </ListItem>
          <ListItem button style={{padding:"8px"}}>
            <ListItemAvatar>
              <Link href="/help" target="_blank">
                <Avatar title="Help" component="span" variant="rounded" alt="Help" style={{color:'transparent',backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/help.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
              </Link>
            </ListItemAvatar>
          </ListItem>
          <ListItem button style={{padding:"8px"}}>
            <ListItemAvatar>
              <Avatar  
                id="basic-button"
                title="Go to"
                component="span" 
                variant="rounded"  
                alt="Go to" 
                style={{color:'transparent',backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/folder_str.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} 
                onClick={goToFolder} 
              />                  
            </ListItemAvatar>
            <Menu
              id="basic-menu"
              anchorEl={anchorElStrMenu}
              open={openStrMenu}
              onClose={goToFolderStrMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {folderStr.map((data, key) => {
                    return (
                      <MenuItem key={key} title={data.path} onClick={() => { goToFolderStrMenu(data.path)}}>
                        <ListItemIcon>
                          { 
                            data.name === 'desktop' 
                            ? 
                            <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/type/desktop_file.png)'}}></span> 
                            : 
                            <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/type/folder_file.png)'}}></span>}
                        </ListItemIcon>
                        {data.name}
                      </MenuItem>
                    )
                })
              }
            </Menu>
          </ListItem>
          <ListItem button style={{padding:'8px'}}>
            <ListItemAvatar>
              <Avatar title="Create Folder" component="span" variant="rounded"  alt="Create Folder" style={{color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/folder_add.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}   onClick={() => createFile('folder')} />
            </ListItemAvatar>
          </ListItem>
          <input
            className={classes.upload_file}
            id="contained-button-file"
            type="file"
            onChange={(e) => uploadFileFnc(e)}
          />
          <ListItem button  style={{padding:"8px"}}>
            <ListItemAvatar>
              <label htmlFor="contained-button-file">
                <Avatar title='Upload File' component="span" variant="rounded"  alt="Upload File" style={{color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/file_upload.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', cursor: 'pointer'}} />
              </label>
            </ListItemAvatar>
          </ListItem>
        </List>
      </Drawer>
      <Dialog    
        id='shutdownDialog'
        open={opendialog}
        className={classes.logout_dialog_box}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"   
        fullScreen
        style={{margin:"48px 0 0 58px"}}
      >
        <DialogContent className={classes.menu_background}>
          <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3}}>
            <Grid container spacing={0}>            
              <Grid item xs={12}>
                <Box sx={{display: 'flex'}}>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    style={{
                            fontWeight: 'bold',                          
                            whiteSpace: 'nowrap', 
                            display: 'block', 
                            margin: 'auto auto', 
                            textAlign:'center', 
                            overflow: 'hidden', 
                            textOverflow: 'ellipsis',
                            cursor: 'pointer'
                          }}
                    onClick={handleClose}
                  >Hide Applications</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} style={{margin: '0 0 0px 0'}}>              
                <Divider style={{margin: '4px 0 20px 0', background: themeHlp().palette.primary.gradient}} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{margin: '0 0 20px 0'}}>
                { !accountAppOn &&
                  <>
                    <Avatar title="Account" component="span" variant="rounded" alt="Account" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/account.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => setAccountAppOn(true) } />
                    <Typography title="Account" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Account</Typography>
                  </>
                }
                { accountAppOn &&
                  <AccountApp value={true} />
                }
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{margin: '0 0 20px 0'}}>
                { !fileFinderAppOn &&
                  <>
                    <Avatar title="File Finder" component="span" variant="rounded" alt="File Finder" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/filefinder.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => setFileFinderAppOn(true) } />
                    <Typography title="File Finder" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>File Finder</Typography>
                  </>
                }
                { fileFinderAppOn &&
                  <FileFinderApp value={true} />
                }
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{margin: '0 0 20px 0'}}>
                { !contactAppOn &&
                  <>
                    <Avatar title="Contact" component="span" variant="rounded" alt="Contact" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/contact.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => setContactAppOn(true) } />
                    <Typography title="Contact" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Contact</Typography>
                  </>
                }
                { contactAppOn &&
                  <ContactApp value={true} onHeader={false} />
                }                  
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{margin: '0 0 20px 0'}}>
                { !imageViewerAppOn &&
                  <>
                    <Avatar title="Image Viewer" component="span" variant="rounded" alt="Image Viewer" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/imageviewer.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => setImageViewerAppOn(true) } />
                    <Typography title="Image Viewer" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Image Viewer</Typography>
                  </>
                }
                { imageViewerAppOn &&
                  <ImageViewerApp value={true} />
                } 
              </Grid>
              
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{margin: '0 0 20px 0'}}>
                { !mediaPlayerAppOn &&
                  <>
                    <Avatar title="Media Player" component="span" variant="rounded" alt="Media Player" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => setMediaPlayerAppOn(true) } />
                    <Typography title="Media Player" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Media Player</Typography>
                  </>
                }
                { mediaPlayerAppOn &&
                  <MediaPlayerApp value={true} />
                }   
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{margin: '0 0 20px 0'}}>
                { !documentReaderAppOn &&
                  <>                  
                    <Avatar title="Document Reader" component="span" variant="rounded" alt="Document Reader" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/documentreader.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => setDocumentReaderAppOn(true) } />                
                    <Typography title="Document Reader" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Document Reader</Typography>
                  </>
                }
                { documentReaderAppOn &&                
                  <DocumentReaderApp value={true} />
                } 
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{margin: '0 0 20px 0'}}>
                { !archiveReaderAppOn &&
                  <>                  
                    <Avatar title="Archive Reader" component="span" variant="rounded" alt="Archive Reader" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/archivereader.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => setArchiveReaderAppOn(true) } />                
                    <Typography title="Archive Reader" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Archive Reader</Typography>
                  </>
                }
                { archiveReaderAppOn &&                
                  <ArchiveReaderApp value={true} />
                } 
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{margin: '0 0 20px 0'}}>
                { !codeBuilderAppOn &&
                  <>                  
                    <Avatar title="Code Builder" component="span" variant="rounded" alt="Code Builder" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => setCodeBuilderAppOn(true) } />                
                    <Typography title="Code Builder" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Code Builder</Typography>
                  </>
                }
                { codeBuilderAppOn &&                
                  <CodeBuilderApp value={true} />
                } 
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{margin: '0 0 20px 0'}}>
                { !notesAppOn &&
                  <>                  
                    <Avatar title="Notes" component="span" variant="rounded" alt="Notes" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => setNotesAppOn(true) } />                
                    <Typography title="Notes" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Notes</Typography>
                  </>
                }
                { notesAppOn &&                
                  <NotesApp value={true} />
                } 
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{margin: '0 0 20px 0'}}>
                { !linkStorageAppOn &&
                  <>
                    <Avatar title="Link Storage" component="span" variant="rounded" alt="Link Storage" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/linkstorage.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => setLinkStorageAppOn(true) } />
                    <Typography title="Link Storage" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Link Storage</Typography>
                  </>
                }
                { linkStorageAppOn &&
                  <LinkStorageApp value={true} />
                } 
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{margin: '0 0 20px 0'}}>
                { !beautySalonAppointmentAppOn &&
                  <>
                    <Avatar title="Beauty Salon Appointment" component="span" variant="rounded" alt="Beauty Salon Appointment" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/beautysalonappointment.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => setBeautySalonAppointmentAppOn(true) } />
                    <Typography title="Beauty Salon Appointment" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Beauty Salon Appointment</Typography>
                  </>
                }
                { beautySalonAppointmentAppOn &&
                  <BeautySalonAppointmentApp value={true} />
                } 
              </Grid>

            </Grid>    
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SideBar;
