import React, { useLayoutEffect, useState, useEffect, useTransition, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import InputAdornment from '@material-ui/core/InputAdornment';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CancelSharpIcon from '@material-ui/icons/Close';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import BorderInnerIcon from '@material-ui/icons/BorderInner';
import ErrorIcon from '@material-ui/icons/Error';
import SearchIcon from '@material-ui/icons/Search';
import { styled } from '@material-ui/core/styles';
import { makeStyles} from '@material-ui/core/styles';
import { themeHlp }  from '../Helper/ThemeHelper.js';
import  appResizeWindowHlp  from '../Helper/AppResizeWindowHelper.js';
import { addbeautysalonusersSrv } from '../AdtService/AddBeautySalonUsersService.js';
import { getbeautysalonusersSrv } from '../AdtService/GetBeautySalonUsersService.js';
import { deletebeautysalonusersSrv } from '../AdtService/DeleteBeautySalonUsersService.js';
import { addbeautysalonclientsSrv } from '../AdtService/AddBeautySalonClientsService.js';
import { getbeautysalonclientsSrv } from '../AdtService/GetBeautySalonClientsService.js';
import { updatebeautysalonclientsSrv } from '../AdtService/UpdateBeautySalonClientsService.js';
import { calcbeautysalonclientstransactionsSrv } from '../AdtService/CalcBeautySalonClientsTransactionsService.js';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),    
    minWidth: 180,
    color: themeHlp().palette.blackText.light,
    backgroundColor: themeHlp().palette.secondary.light,  
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      width: '380px',
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        color: themeHlp().palette.blackText.main,        
      },
    },
    
  }
}));

const useStyles = makeStyles((theme) => ({
  menu_box:{
    width:'100%', 
    height:'340px', 
    margin:'auto auto', 
    background: 'linear-gradient(135deg, rgb(38, 93, 140) 0%, rgb(38, 93, 140) 15%, rgb(0, 140, 183) 50%)', 
    opacity:'1', 
    borderRadius:'15px', 
    border:'4px solid #f5a33e'
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: themeHlp().palette.secondary.light,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialog_action: {
    padding: '0px',
  },
  dialog_content: {
    padding: '0px',
  },
  iconbutton_content: {
    width:'22px',
    height: '22px',    
    color: themeHlp().palette.primary.light,
    '&:hover' : {
      color: themeHlp().palette.primary.main
    },
    '&:active' : {
      color: themeHlp().palette.primary.dark,    
    }
  },
  file_icon: {
    width: '24px',
    height: '24px',     
    backgroundRepeat: 'no-repeat',
    backgroundSize: '22px',
    backgroundPosition: '0px 2px',
    padding: '0 24px 0 0',
  },
  font_badge: {
    '& .MuiBadge-colorPrimary': {
      background: themeHlp().palette.primary.gradient,
      color: themeHlp().palette.secondary.light
    }
  },
  text_box: {
    width:'100%',
    margin: '4px 0',
    borderBottom: '1px solid ' + themeHlp().palette.primary.light,
    float: 'left',
    fontSize: '20px', 
    '& .MuiInputLabel-root': {
      color: themeHlp().palette.blackText.light,
    },
    '& .MuiInputLabel-shrink': {
      color: themeHlp().palette.primary.light,
    },
    '&:hover': {
      border: '1px solid ' + themeHlp().palette.primary.light,
      borderStyle: 'none none solid none',
    }
  },
  text_box_icon: {
    width:'100%',
    margin: '4px 0',
    borderBottom: '1px solid ' + themeHlp().palette.primary.light,
    padding: '0px 24px',
    float: 'left',
    fontSize: '20px', 
    '& .MuiInputLabel-root': {
      color: themeHlp().palette.blackText.light,
      padding: '0px 24px'
    },
    '& .MuiInputLabel-shrink': {
      color: themeHlp().palette.primary.light,
    },
    '&:hover': {
      border: '1px solid ' + themeHlp().palette.primary.light,
      borderStyle: 'none none solid none',
    }
  },
  search_icon: {
    color: themeHlp().palette.blackText.light,
    position:'absolute', 
    top: '24px', 
    left: '0px'
  },
  select_box: {
    width:'100%',
    margin: '4px 0',
    background: 'white',
    borderBottom: '1px solid ' + themeHlp().palette.primary.light,
    float: 'left',    
    textAlign: 'left',
    fontSize: '16px', 
    color: themeHlp().palette.primary.light,
    '& .MuiSelect-root': {
      background: 'white',    
    },
  },
  datatime_box: {
    width:'100%',
    height: '26px',
    display: 'block',
    margin: '4px 0',    
    paddingTop: '6px',       
    border: 'none',    
    background: 'transparent',    
    color: themeHlp().palette.blackText.main,
    float: 'left',
    textAlign: 'left',
    fontSize: '16px', 
    borderBottom: '1px solid ' + themeHlp().palette.primary.light,
    '& .MuiInputLabel-root': {
      color: themeHlp().palette.primary.light
    },
    '&:hover': {
      border: '1px solid ' + themeHlp().palette.primary.light,
      borderStyle: 'none none solid none',
    },
    '&:active': {
      border: '1px solid ' + themeHlp().palette.primary.light,
      borderStyle: 'none none solid none',
    }
  }, 
  input_label: {
    color: themeHlp().palette.primary.light, 
    float:'left',
    fontSize: '0.775rem'
  },
  dialog_text_title: {
    color: themeHlp().palette.primary.main,    
    padding: '16px',
    textAlign: 'left'
  },
  dialog_text_box: {
    width:'100%',
    border: '1px solid ' + themeHlp().palette.fogGrey.main,
    padding: '8px',
    '& input.Mui-disabled': {
      color: themeHlp().palette.blackText.main
    }
  },
  button_witout_icon: {
    width:'100%',    
    margin: '7px 0 6px 0',
    background: themeHlp().palette.primary.light,
    textTransform: 'capitalize',
    border: '1px solid ' + themeHlp().palette.secondary.light,
    '&:hover' : {
      background: themeHlp().palette.primary.main,
      color: themeHlp().palette.secondary.light,
    },
    '&:active' : {
      background: themeHlp().palette.primary.dark,
      color: themeHlp().palette.secondary.light,
    }
  }, 
  button_small_witout_icon: {
    width:'100%',
    height: '24px',
    margin: '7px 0 6px 0',
    background: themeHlp().palette.primary.light,
    textTransform: 'capitalize',
    border: '1px solid ' + themeHlp().palette.secondary.light,
    '&:hover' : {
      background: themeHlp().palette.primary.main,
      color: themeHlp().palette.secondary.light,
    },
    '&:active' : {
      background: themeHlp().palette.primary.dark,
      color: themeHlp().palette.secondary.light,
    }
  },
  clients_table: {
   // background: 'blue',
    '&:nth-child(odd)': {
      background: '#f7f7f7'
    },
    '&:nth-child(even)': {
      background: 'white'
    }
  },
  bs_user_info: {
    display: 'block',
    padding: '8px',
    margin: '8px',
    color: '#424242',
    border: '1px solid',
    borderColor: '#e0e0e0',
    borderRadius: '8px',
    fontSize: '0.875rem',
    fontWeight: '700',
  }
}));


let selectableUserKey = 0;
let selectableUserId = '';
let selectableUserEmail = '';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BeautySalonAppointmentApp = (props) => {

  const classes = useStyles();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [open, setOpen] = React.useState(false);
  const [toolbarHidden, setToolbarHidden] = useState(false);  
  const [newUserMode, setNewUserMode] = useState(false);
  const [contentAddMode, setContentAddMode] = useState(false);
  const [calculateMode, setCalculateMode] = useState(false);
  const [doSearchMode, setDoSearchMode] = useState(false);
  const [notes, setNotes] = useState([]);
  const [currentNote, setCurrentNote] = useState({
    caseId: '',
    appointmentDate: '',
    clientName: '',
    phoneNumber: '',
    appointmentStatus: '',
    action: '',
    sum: 0
  });

  const regexValidation = {
    dateTime : /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?$/m,
    email : /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    empty: /^(?!\s*$).+/
  }

  const bsLang = {
    en: {
      new_user: 'New User',
      show_user_info: 'Show User Info',
      hide_user_info: 'Hide User Info',
      man_code: 'Manager\'s Code',
      emp_code: 'Employee\'s Code',
      leave_the_group: 'Leave the Group',
      new_note: 'Add Note',
      load_notebook: 'Load Notebook',
      calculate: 'Calculate',
      quantity: 'Quantity',
      total_amount: 'Total Amount',
      search: 'Search',      
      font_size: 'Font size',
      reset_font_size: 'Reset Font Size',
      increase_font_size: 'Increase Font size',
      decrease_font_size: 'Decrease Font size',
      language: 'Language',
      language_country_code: 'en-US',
      select_user: 'Select User',
      select_date: 'Select Date',
      date: 'Date',
      start_date: 'Start Date',
      end_date: 'End Date',
      client_name: 'Client Name',
      phone_number: 'Phone Number',
      action: 'Action',
      sum: 'Sum',
      cancel: 'Cancel',
      save: 'Save',
      confirm: 'Confirm',
      invalidate: 'Invalidate',
      close: 'Close',
      warning_message_before_confirmation_user: 'Make sure the data is correct before confirming. After clicking the "Confirm" button, you can no longer make changes.',
      warning_message_before_confirmation_admin: 'The changes made will be visible to the employee as well․',
      warning_message_before_cancel_user: 'Confirm by clicking the "invalidate" button once more. After clicking the "Invalidate" button, you can no longer make changes.',
      warning_message_before_cancel_admin: 'The changes made will be visible to the employee as well․',
      owner: 'Owner',
      manager: 'Manager',
      employee: 'Employee',
      salon_name: 'The Name of the Salon',
      salon_code: 'Code',
      admin_email: 'Admin\'s Email Address',
      incorrect_admin_email: 'Admin Email Address is incorrect',
      validation_date_time: 'The "Date" field must now be filled out',
      validation_empty_client_name: 'The "Client Name" field must now be filled out',
      validation_empty_phone_number: 'The "Phone Number" field must now be filled out',
      validation_empty_action: 'The "Action" field must now be filled out',
      validation_empty_salon_name: '"The Name of the Salon" field must now be filled out',
      validation_empty_code: 'The "Code" field must now be filled out',
      validation_empty_admin_email: '"Admin\'s Email Address" field must now be filled out',
      validation_email: 'Please enter a valid email address',
    },
    hy: {
      new_user: 'Նոր Օգտատեր',
      show_user_info: 'Ցույց Տալ Օգտատիրոջ Ինֆորմացիան',
      hide_user_info: 'Թաքցնել Օգտատիրոջ Ինֆորմացիան',
      man_code: 'Կառավարչի Կոդը',
      emp_code: 'Աշխատողի Կոդը',
      leave_the_group: 'Լքել Խումբը',
      new_note: 'Նշում Ավելացնել',
      load_notebook: 'Բեռնել Նոթատետրը',
      calculate: 'Հաշվարկ Անել',
      quantity: 'Քանակը',
      total_amount: 'Ընդհանուր Գումարը',
      search: 'Որոնել',
      font_size: 'Տառաչափ',
      reset_font_size: 'Վերակայել Տառաչափը',
      increase_font_size: 'Մեծացնել տառաչափը',
      decrease_font_size: 'Նվազեցնել տառաչափը',
      language: 'Ընտրել Լեզուն',
      language_country_code: 'hy-AM',
      select_user: 'Ընտրեք Օգտատիրոջը',
      select_date: 'Նշեք Ամսաթիվը',
      date: 'Ամսաթիվ',
      start_date: 'Մեկնարկի Ամսաթիվը',
      end_date: 'Ավարտի Ամսաթիվը',
      client_name: 'Հաճախորդի Անունը',
      phone_number: 'Հեռ․ Համար',
      action: 'Գործողություն',
      sum: 'Գումար',
      cancel: 'Հետաձգել',
      save: 'Պահպանել',
      confirm: 'Հաստատել',
      invalidate: 'Անվավեր Ճանաչել',
      close: 'Փակել',
      warning_message_before_confirmation_user: 'Հաստատելուց առաջ համոզվեք տվյալների ճշտության մեջ։ "Հաստատել կոճակը" սեղմելուց հետո այլևս չեք կարող փոփոխություն կատարել։',
      warning_message_before_confirmation_admin: 'Արված փոփոխությունները տեսանելի է լինելու նաև աշխատողի մոտ։',
      warning_message_before_cancel_user: 'Հաստատեք ևս մեկ անգամ սեղմելով "Անվավեր Ճանաչել" կոճակը։ Անվավեր Ճանաչելուց հետո այլևս չեք կարող փոփոխություն կատարել։',
      warning_message_before_cancel_admin: 'Արված փոփոխությունները տեսանելի է լինելու նաև աշխատողի մոտ։',
      owner: 'Տնօրեն',
      manager: 'Կառավարիչ',
      employee: 'Աշխատող',
      salon_name: 'Սրահի Անվանումը',
      salon_code: 'Կոդը',
      admin_email: 'Ադմինիստրատորի Էլփոստի Հասցեն',
      incorrect_admin_email: 'Ադմինիստրատորի Էլփոստի Հասցեն սխալ է',
      validation_date_time: '"Ամսաթիվ" դաշտը լրացնելը պարտադիր է',
      validation_empty_client_name: '"Հաճախորդի Անունը" դաշտը լրացնելը պարտադիր է',
      validation_empty_phone_number: '"Հեռ․ Համար" դաշտը լրացնելը պարտադիր է',
      validation_empty_action: '"Գործողություն" դաշտը լրացնելը պարտադիր է',
      validation_empty_salon_name: '"Սրահի Անվանումը" դաշտը լրացնելը պարտադիր է',
      validation_empty_code: '"Կոդը" դաշտը լրացնելը պարտադիր է',
      validation_empty_admin_email: '"Ադմինիստրատորի Էլփոստի Հասցեն" դաշտը լրացնելը պարտադիր է',
      validation_email: 'Խնդրում ենք մուտքագրել վավեր էլփոստի հասցե',      
    }
  }
  
  let debounceSearching;
  let sumRef = useRef(null);

  const [appointmentData, setAppointmentData] = useState({
    dateTime: '',
    clientName: '',
    cellphone: '',
    action: '',
    sum: 0
  });

  const [appointmentCalculationData, setAppointmentCalculationData] = useState({
    startDateTime: '',
    endDateTime: '',
  });


  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [openAppointmentDialog, setOpenAppointmentDialog] = useState(false);
  const [appointmentApprovalProcess, setAppointmentApprovalProcess] = useState(false);
  const [appointmentCancelProcess, setAppointmentCancelProcess] = useState(false);

  const handleClickOpenAppointmentDialog = (clientId) => {
    setOpenAppointmentDialog(true);
    if(selectableUserId === ''){    
      goToGetCurrentAppointment(clientId, null, '2.1');
    }else{
      goToGetCurrentAppointment(clientId, selectableUserId, '2.2');
    }
  };

  const handleCloseAppointmentDialog = () => {
    setOpenAppointmentDialog(false);
  };

  const [selectableRole, setSelectableRole] = useState(1);
  const [salonNameOrCode, setSalonNameOrCode] = useState('');
  const [email, setEmail] = useState('');

  let searchKeyword = useRef('');

  const [manCode, setManCode] = useState('');
  const [empCode, setEmpCode] = useState('');

  const [calculationInfo, setCalculationInfo] = useState({
    totalQuantity: 0,
    totalAmount: 0
  })

  const [contentFontSize, setContentFontSize] = useState(16);
  const [menuLanguage, setMenuLanguage] = useState('hy');
  const [page, setPage] = useState(1);
  const rowCount = 10;
  const titleDisplaySize = 640;

  const [anchorFontSizeEl, setAnchorFontSizeEl] = React.useState(null);
  const openFontSize = Boolean(anchorFontSizeEl);
  const openFontSizeMenu = (event) => {
    setAnchorFontSizeEl(event.currentTarget);
  };
  const closeFontSizeMenu = () => {
    setAnchorFontSizeEl(null);
  };

  const [anchorLanguageEl, setAnchorLanguageEl] = React.useState(null);
  const openLanguage = Boolean(anchorLanguageEl);
  const openLanguageMenu = (event) => {
    setAnchorLanguageEl(event.currentTarget);
  };
  const closeLanguageMenu = () => {
    setAnchorLanguageEl(null);
  };

  const handleClickOpen = () => {
    if(selectableUserId === ''){
      goToGetAppointment(page, null, searchKeyword.current, '1.1');
    }else{
      goToGetUserAppointment(selectableUserId, selectableUserKey, searchKeyword.current, '1.2')
    }

    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('beautysalon') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount <2){
      allApps.push('beautysalon');
      setOpen(true);
      
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
        apps: allApps,
        appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount + 1
      }));    
      
      setTimeout(() => {
        appsInWindows('load');
      },100);
    }
  };

  const handleClose = () => {
    setOpen(false);
    if(props.appCloses){
      props.appCloses(true);
    }

    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;
    if(allApps.indexOf('beautysalon') !== -1){
      allApps.splice(allApps.indexOf('beautysalon'), 1);
    }

    localStorage.setItem('movwin', JSON.stringify({
      windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
      apps: allApps,
      appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount - 1
    }));
  };

  const appsInWindows = (event) => {
    if(event === 'change'){
      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode < 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode + 1,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }else if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode === 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: 0,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }

      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).appCount === 0){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: 1
        }));
      }
    }

    setWindowWidth(windowWidth - 1);  
    appResizeWindowHlp('beautySalonAppointmentDialogBox');
  }

    

  const loadNotebookFnc = () => {    
    searchKeyword.current = '';
    if(selectableUserId === ''){
      goToGetAppointment(page, null, searchKeyword.current, '1.1');
    }else{
      goToGetUserAppointment(selectableUserId, selectableUserKey, searchKeyword.current, '1.2')
    }

    setNotes([]);
    setPage(1);
    setNewUserMode(false); 
    setContentAddMode(false);
    setCalculateMode(false);
    setDoSearchMode(false); 
    setSalonNameOrCode('');
    setEmail('');
  }

  const searchModeFnc = () => {
    setNewUserMode(false); 

    if(!doSearchMode){
      setDoSearchMode(true);
      setNewUserMode(false);
      setContentAddMode(false);      
      setCalculateMode(false);
    }else{
      setDoSearchMode(false);   
      if(searchKeyword.current !== ''){
        searchKeyword.current = '';      
        if(selectableUserId === ''){
          goToGetAppointment(page, null, searchKeyword.current, '1.1');
        }else{
          goToGetUserAppointment(selectableUserId, selectableUserKey, searchKeyword.current, '1.2')
        }
      }
    }    
  } 

  const noteSearch = () => {
    if(debounceSearching !== undefined){
      clearTimeout(debounceSearching);
    }
    debounceSearching = setTimeout(function() {
      if(selectableUserId === ''){
        goToGetAppointment(page, null, searchKeyword.current, '1.1');
      }else{
        goToGetUserAppointment(selectableUserId, selectableUserKey, searchKeyword.current, '1.2')
      }
    },1200);
  }

  ////getbeautysalonclientsSrv///////////////////////////////////
  const goToGetAppointment = (page, userId, keyword, callType) => {    
    setNotes([]);
    selectableUserEmail = bsLang[menuLanguage].select_user;

    const getbeautysalonclientsParams = {
      user_id: userId,
      page: page,
      keyword: keyword,
      call_type: callType
    }
    getbeautysalonclientsSrv(getbeautysalonclientsParams)
    .then(response => {      
      if(response && response.statusCode === 200){        
        if(response.data?.notesQty !== undefined){
          notesBuilder(response.data, selectableUserKey);
        }
      }/*else if(response.error){
        setAlertMessage(response.error.message);
        setOpenAlertDialog(true);
      }*/
    }).catch(err => {
      console.log(err);
    })
  }

  const goToGetCurrentAppointment = (clientId, userId, callType) => {    
    const getbeautysalonclientsParams = {
      user_id: userId,
      client_id: clientId,
      call_type: callType
    }
    getbeautysalonclientsSrv(getbeautysalonclientsParams)
    .then(response => {      
      if(response && response.statusCode === 200){        
        if(response.data?.currentClientsNote !== undefined){
          setCurrentNote({...response.data.currentClientsNote, role: response.data.role});
        }
      }else if(response.error){
        setAlertMessage(response.error.message);
        setOpenAlertDialog(true);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const goToGetUserAppointment = (userId, selectedUserKey, keyword, callType) => {
    setNotes([]);
    setContentAddMode(false);

    const getbeautysalonclientsParams = {
      user_id: userId,
      page: page,
      keyword: keyword,
      call_type: callType
    }
    getbeautysalonclientsSrv(getbeautysalonclientsParams)
    .then(response => {
      if(response && response.statusCode === 200){
        if(response.data?.notesQty !== undefined){
          selectableUserKey = selectedUserKey;
          selectableUserId = userId;
          notesBuilder(response.data, selectedUserKey);
        }
      }else if(response.error){
        setAlertMessage(response.error.message);
        setOpenAlertDialog(true);
      }
    }).catch(err => {
      console.log(err);
    })
  }
/////////////////////////////////////////////////////////////////

  const goToCreateUser = () => {
    if(!regexValidation.email.test(email)){
      setAlertMessage(bsLang[menuLanguage].validation_email);
    }
    if(!regexValidation.empty.test(email)){
      setAlertMessage(bsLang[menuLanguage].validation_empty_admin_email);
    }    
    if(!regexValidation.empty.test(salonNameOrCode) && selectableRole === 1){
      setAlertMessage(bsLang[menuLanguage].validation_empty_salon_name);
    }else if(!regexValidation.empty.test(salonNameOrCode) && (selectableRole === 2 || selectableRole === 3)){
      setAlertMessage(bsLang[menuLanguage].validation_empty_code);
    } 
    if(!regexValidation.email.test(email) ||
      !regexValidation.empty.test(email) ||
      !regexValidation.empty.test(salonNameOrCode)
    ){
      setOpenAlertDialog(true);
    }else{
      const addbeautysalonusersParams = {
        salon_name: selectableRole === 1 ? salonNameOrCode : '',
        code: (selectableRole === 2 || selectableRole === 3) ? salonNameOrCode: '',
        role: selectableRole,
        email: email
      }
      addbeautysalonusersSrv(addbeautysalonusersParams)
      .then(response => {
        if(response && response.statusCode === 201){
          loadNotebookFnc();
        }else if(response.error){
          setAlertMessage(response.error.message);
          setOpenAlertDialog(true);
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }

  const goToGetUser = () => {

    if(empCode === ''){
      getbeautysalonusersSrv()
      .then(response => {
        if(response && response.statusCode === 200){
          setManCode(response.data.managerCode);
          setEmpCode(response.data.employeeCode);
        }else if(response.error){
          setAlertMessage(response.error.message);
          setOpenAlertDialog(true);
        }
      }).catch(err => {
        console.log(err);
      })
    }else{
      setManCode('');
      setEmpCode('');
    }
  }

  const goToDeleteUser = () => {

    if(!regexValidation.email.test(email)){
      setAlertMessage(bsLang[menuLanguage].validation_email);
    }
    if(!regexValidation.empty.test(email)){
      setAlertMessage(bsLang[menuLanguage].validation_empty_admin_email);
    }    

    if(!regexValidation.email.test(email) ||
       !regexValidation.empty.test(email)
    ){
      setOpenAlertDialog(true);
    }else{
      const deletebeautysalonusersParams = {
        email: email
      }
      deletebeautysalonusersSrv(deletebeautysalonusersParams)
      .then(response => {
        if(response && response.statusCode === 200){
          loadNotebookFnc();
        }else if(response.error){
          setAlertMessage(response.error.message);
          setOpenAlertDialog(true);
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }

  const goToCreateAppointment = () => {   

    if(!regexValidation.empty.test(appointmentData.action)){
      setAlertMessage(bsLang[menuLanguage].validation_empty_action);
    }
    if(!regexValidation.empty.test(appointmentData.cellphone)){
      setAlertMessage(bsLang[menuLanguage].validation_empty_phone_number);
    }
    if(!regexValidation.empty.test(appointmentData.clientName)){
      setAlertMessage(bsLang[menuLanguage].validation_empty_client_name);
    }
    if(!regexValidation.dateTime.test(appointmentData.dateTime + ':00')){
        setAlertMessage(bsLang[menuLanguage].validation_date_time);
    }

    if(!regexValidation.empty.test(appointmentData.action) ||
       !regexValidation.empty.test(appointmentData.cellphone) ||
       !regexValidation.empty.test(appointmentData.clientName) ||
       !regexValidation.dateTime.test(appointmentData.dateTime + ':00')
      ){
      setOpenAlertDialog(true);
    }else{
      setContentAddMode(false);
      
      const addbeautysalonclientsParams = {
        user_id: selectableUserId,
        date_time: appointmentData.dateTime,
        client_name: appointmentData.clientName,
        cellphone: appointmentData.cellphone,
        action: appointmentData.action,
      }
      addbeautysalonclientsSrv(addbeautysalonclientsParams)
      .then(response => {        
        if(response && response.statusCode === 201){
          if(selectableUserId === ''){
            goToGetAppointment(page, null, searchKeyword.current, '1.1');
          }else{
            goToGetUserAppointment(selectableUserId, selectableUserKey, searchKeyword.current, '1.2')
          }
        }else if(response.error){
          setAlertMessage(response.error.message);
          setOpenAlertDialog(true);
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }


  const goToUpdateAppointment = (caseId, confirmationType) => {
    const updatebeautysalonclientsParams = {
      case_id: caseId,
      sum: currentNote.sum,
      other_data: (currentNote.role === 1 || currentNote.role === 2) ? currentNote : null,
    }

    if(confirmationType === 'invalidate'){
      updatebeautysalonclientsParams.sum = -1;
    }else if(confirmationType === 'approve'){
      if(currentNote.sum >= 0){    
        updatebeautysalonclientsParams.sum = currentNote.sum;
      }
    }

    updatebeautysalonclientsSrv(updatebeautysalonclientsParams)
    .then(response => {
      if(response && response.statusCode === 200){          
        handleCloseAppointmentDialog();
        setAppointmentApprovalProcess(false);
        setAppointmentCancelProcess(false);
        if(selectableUserId === ''){
          goToGetAppointment(page, null, searchKeyword.current, '1.1');
        }else{
          goToGetUserAppointment(selectableUserId, selectableUserKey, searchKeyword.current, '1.2')
        }
      }else if(response.error){
        setAlertMessage(response.error.message);
        setOpenAlertDialog(true);
      }
    }).catch(err => {
      console.log(err);
    }) 
  }

  const goToCalculateTransactions = () => {
    if(appointmentCalculationData.startDateTime !== '' && appointmentCalculationData.endDateTime !== ''){
      const calcbeautysalonclientstransactionsParams = {
        user_id: selectableUserId,
        start_date_time: appointmentCalculationData.startDateTime,
        end_date_time: appointmentCalculationData.endDateTime
      }
      calcbeautysalonclientstransactionsSrv(calcbeautysalonclientstransactionsParams)
      .then(response => {
        if(response && response.statusCode === 200){        
          setCalculationInfo({...calculationInfo, totalQuantity: response.data.totalQuantity, totalAmount: response.data.totalAmount});      
        }else if(response.error){
          setAlertMessage(response.error.message);
          setOpenAlertDialog(true);
        }
      }).catch(err => {
        console.log(err);
      })
    }else{      
      setAlertMessage(bsLang[menuLanguage].select_date);
      setOpenAlertDialog(true);
    }
  }

//////////////////////////////////////////////////////////
  const paginationChange = (env, currentPage) => {
   setPage(currentPage);
   setContentAddMode(false);
  };

  const processingForBackgroundSwitch = (param) => {
    switch(param) {
      case 'planning':
        return themeHlp().palette.greyText.gradient;
      case 'pending':
        return themeHlp().palette.warning.gradient;
      case 'cancel':
        return themeHlp().palette.error.gradient;
      case 'done':
        return themeHlp().palette.success.gradient;
      default:
        return 'foo';
    }
  }


  function notesBuilder(data, selectedUserKey) {
    setNotes(oldClientsNotes => [...oldClientsNotes,
      <>       
      {data?.users && 
        <FormControl fullWidth>
          <InputLabel style={{color: themeHlp().palette.primary.light}} id="beauty-salon-select-label">{selectableUserEmail}</InputLabel>
          <Select
            labelId="beauty-salon-select-label"
            id="beauty-salon-select"
            className={classes.select_box}
            value={selectedUserKey}
          >
            {data.users.map((row, key) => (            
              <MenuItem key={key + 1} onClick={() => { goToGetUserAppointment(row.emailId, row.role, searchKeyword.current, '1.2'); selectableUserEmail = row.email}}>{row.firstName} {row.lastName} ({row.email})</MenuItem>
          
            ))}
          </Select>          
        </FormControl>
      }
      <TableContainer  component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead style={{background: '#763876'}}>
            <TableRow>
              <TableCell style={{color: 'white', fontSize: contentFontSize}}>{bsLang[menuLanguage].date}</TableCell>
              <TableCell style={{color: 'white', fontSize: contentFontSize}} align="right">{bsLang[menuLanguage].client_name}</TableCell>
              <TableCell style={{color: 'white', fontSize: contentFontSize}} align="right">{bsLang[menuLanguage].phone_number}</TableCell>
              <TableCell style={{color: 'white', fontSize: contentFontSize}} align="right">{bsLang[menuLanguage].action}</TableCell>
              <TableCell style={{color: 'white', fontSize: contentFontSize}} align="right">{bsLang[menuLanguage].sum}</TableCell>            
            </TableRow>
          </TableHead>
          <TableBody>
            {data.clientsNotes.map((row, key) => (              
              <TableRow
                key={key}
                className={classes.clients_table}              
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >                
                <TableCell style={{fontSize: contentFontSize}} component="th" scope="row">
                  <Button
                    variant="text"
                    style={{background: processingForBackgroundSwitch(row.appointmentStatus), fontSize: contentFontSize}}       
                    onClick={() => handleClickOpenAppointmentDialog(row.caseId)}
                  >
                    {new Date(row.appointmentDate).toLocaleString(bsLang[menuLanguage].language_country_code, {timeZone: timeZone,
                      dateStyle: 'medium',//full, long, medium, short
                      timeStyle: 'short', //full, long, medium, short
                      hourCycle: 'h24', //h12, h24
                    })}
                  </Button>
                </TableCell>
                <TableCell style={{fontSize: contentFontSize}} align="right">{row.clientName}</TableCell>
                <TableCell style={{fontSize: contentFontSize}} align="right">{row.phoneNumber.length > 5 ? new Array(row.phoneNumber.length - 4 + 1).join('x') + row.phoneNumber.slice( -4) : row.phoneNumber}</TableCell>
                <TableCell style={{fontSize: contentFontSize}} align="right">{row.action}</TableCell>
                <TableCell style={{fontSize: contentFontSize}} align="right">{row.sum}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box style={{
        background: 'white',         
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        borderRadius: '0 0 8px 8px',
        paddingBottom: '20px',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      }}>
      <Pagination
          count={Math.ceil(parseInt(data.notesQty)/rowCount)}
          size="medium"
          page={page}
          variant="outlined"
          style={{ paddingTop: '20px',}}
          shape="rounded"                           
          onChange={paginationChange}                         
        />    
        </Box>                
      </>
    ])    
  }

  ///////////////////////////////////////////////
  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);      
    }

    window.addEventListener('resize', updateSize);
    updateSize();
   
    return () => {     
      window.removeEventListener('resize', updateSize); 
    }
  },[windowWidth, windowHeight])

  useEffect(() => {
    handleClickOpen();

    if(!localStorage.getItem('movwin')){
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: 0,
        apps: [],
        appCount: 1
      }));
    }     
    
    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('beautysalon') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount < 2){
      setOpen(true);
      setTimeout(() => {
        const muiBackdrop = document.querySelectorAll('.MuiBackdrop-root');
        let newApp = JSON.parse(localStorage.getItem('movwin')).apps;
        newApp.push('beautysalon');
        
        if(muiBackdrop[2]){
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 2
          })); 
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1]) {          
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 1
          }));        
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1] === undefined) {        
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: [],
            appCount: 0,
          }));        
        }
        appsInWindows('load');
      },100);
    }
  },[
    page,
    contentFontSize,
    menuLanguage
  ])

return (
  <>
    <Avatar title="Beauty Salon Appointment" component="span" variant="rounded" alt="Beauty Salon Appointment" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/beautysalonappointment.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={handleClickOpen} />
    <Typography title="Beauty Salon Appointment" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Beauty Salon Appointment</Typography>
    <Dialog id="beautySalonAppointmentDialogBox" fullScreen onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        style={{
          padding: '8px 16px',
          color:'#ffffff', 
          background:'linear-gradient(90deg, rgba(38,93,140,1) 0%, rgba(38,93,140,1) 15%, rgba(0,140,183,1) 100%)'
        }}
        id="customized-dialog-title" onClose={handleClose}
      >
        <Avatar component="span" variant="rounded" style={{width:'24px', height: '24px', display: 'inline-block', marginRight: '8px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/beautysalonappointment.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
        <span>{(windowWidth < titleDisplaySize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0) || (windowWidth < (titleDisplaySize/2) && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) === 0) ? '' : 'Beauty Salon App...'}</span>
        <IconButton
          disabled={document.getElementById('shutdownDialog') ? false : true}
          aria-label="AppsInWindowsIcon"
          onClick={() => appsInWindows('change')}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white',          
            margin:'2px 42px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px',
            opacity: document.getElementById('shutdownDialog') ? '1' : '0.3'
          }}
        >
          <BorderInnerIcon style={{padding: '2px'}} />          
        </IconButton>
        <IconButton 
          aria-label="UnfoldLessMoreIcon"
          title={!toolbarHidden ? 'Hide Toolbar' : 'Show Toolbar'}
          onClick={()=> setToolbarHidden(current => !current)}
          size="small"
          style={{
            width: '18px',
            height: '18px',
            background: themeHlp().palette.blueGrey.dark,
            color:'white',  
            margin:'2px 74px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          {!toolbarHidden ? <UnfoldLessIcon style={{padding: '4px'}} /> : <UnfoldMoreIcon style={{padding: '4px'}} />}
        </IconButton>
        <IconButton 
          aria-label="CancelSharpIcon"
          title="Close"
          onClick={handleClose}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white', 
            margin:'2px 10px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          <CancelSharpIcon style={{padding: '2px'}} /> 
        </IconButton>
      </DialogTitle>
      {!toolbarHidden && 
      <DialogActions id="p0" className={classes.dialog_action}>
        <div id="p1" className={classes.root}>          
          <List style={{ width: '100%', height: '64px', padding: '0px', overflowY: 'auto'}}>
            <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label htmlFor="contained-button-file-image-browse">
                <Avatar title={bsLang[menuLanguage].new_user} component="span" variant="rounded" alt="New User" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/beautysalon/beautysalon_role_create.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => {setNewUserMode(true); setContentAddMode(false); setCalculateMode(false); setDoSearchMode(false);}} />
              </label>
            </ListItem>
            <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label htmlFor="contained-button-file-image-browse">
                <Avatar title={bsLang[menuLanguage].new_note} component="span" variant="rounded" alt="New Note" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/beautysalon/beautysalon_new.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => {setContentAddMode(true); setNewUserMode(false); setCalculateMode(false); setDoSearchMode(false);}} />
              </label>
            </ListItem>
            <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label htmlFor="contained-button-file-image-browse">
                <Avatar title={bsLang[menuLanguage].load_notebook} component="span" variant="rounded" alt="Load Notebook" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/beautysalon/beautysalon_load.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => loadNotebookFnc()} />
              </label>
            </ListItem>            
            <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label htmlFor="contained-button-file-image-browse">
                <Avatar title={bsLang[menuLanguage].calculate} component="span" variant="rounded" alt="Calculate" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/beautysalon/beautysalon_calculate.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => {setCalculateMode(true); setContentAddMode(false); setNewUserMode(false); setDoSearchMode(false);}} />
              </label>
            </ListItem>
            <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label htmlFor="contained-button-file-image-browse">
                <Avatar title={bsLang[menuLanguage].search} component="span" variant="rounded" alt="Search Notes" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/beautysalon/beautysalon_search.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => {searchModeFnc()}} />
              </label>
            </ListItem>
            <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label>
              <Badge badgeContent={contentFontSize} color="primary" className={classes.font_badge}>
                <Avatar title= {bsLang[menuLanguage].font_size} component="span" variant="rounded" alt="Font Size" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/beautysalon/beautysalon_font_reset.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={openFontSizeMenu} />
                </Badge>
              </label>                    
            </ListItem>
            <div>
              <StyledMenu
                id="font-size-menu"
                MenuListProps={{
                  'aria-labelledby': 'font-size-menu',
                }}
                anchorEl={anchorFontSizeEl}
                open={openFontSize}
                onClose={closeFontSizeMenu}
              >
                <MenuItem onClick={() => setContentFontSize(16)}>                
                  <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/beautysalon/beautysalon_font_reset.png)'}}></span>
                  {bsLang[menuLanguage].reset_font_size}
                </MenuItem>
                <MenuItem onClick={() => {contentFontSize < 34 && setContentFontSize(contentFontSize + 2)}}>
                  <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/beautysalon/beautysalon_font_up.png)'}}></span>
                  {bsLang[menuLanguage].increase_font_size}
                </MenuItem>
                <MenuItem onClick={() => {contentFontSize > 8 && setContentFontSize(contentFontSize - 2)}}>
                  <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/beautysalon/beautysalon_font_down.png)'}}></span>
                  {bsLang[menuLanguage].decrease_font_size}
                </MenuItem>
              </StyledMenu>
            </div>
            <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label>
                <Avatar title= {bsLang[menuLanguage].language} component="span" variant="rounded" alt="Language" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/beautysalon/beautysalon_lang.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={openLanguageMenu} />
              </label>                    
            </ListItem>
            <div>
              <StyledMenu
                id="lang-menu"
                MenuListProps={{
                  'aria-labelledby': 'lang-menu',
                }}
                anchorEl={anchorLanguageEl}
                open={openLanguage}
                onClose={closeLanguageMenu}
              >
                <MenuItem onClick={() => {setMenuLanguage('hy'); closeLanguageMenu()}}>
                  <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/beautysalon/beautysalon_lang_hy.png)'}}></span>
                  Armenian (Հայերեն)
                </MenuItem>
                <MenuItem onClick={() => {setMenuLanguage('en'); closeLanguageMenu()}}>
                  <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/beautysalon/beautysalon_lang_en.png)'}}></span>
                  English (Անգլերեն)
                </MenuItem>
              </StyledMenu>
            </div>
          </List>
        </div>
      </DialogActions>
      }

      <DialogContent className={classes.dialog_content}>
        <Box style={{ margin: '8px'}}>
          <Grid container align="center">
            <Grid container spacing={0} align="center" justify="center">
              {newUserMode &&
              <>
                <Grid item xs={12} sm={8} md={6} lg={4} xl={4} style={{padding: 0}}>
                  <Box sx={{my: 2}}>
                    {notes.length === 0 ?
                    <>
                    <FormControl fullWidth>            
                      <Select
                        labelId="beauty-salon-select-label"    
                        id="beauty-salon-select"
                        className={classes.select_box}
                        value={selectableRole}
                      >
                        <MenuItem style={{color: themeHlp().palette.primary.light}} value={1} onClick={() => setSelectableRole(1)}>{bsLang[menuLanguage].owner}</MenuItem>
                        <MenuItem style={{color: themeHlp().palette.primary.light}} value={2} onClick={() => setSelectableRole(2)}>{bsLang[menuLanguage].manager}</MenuItem>
                        <MenuItem style={{color: themeHlp().palette.primary.light}} value={3} onClick={() => setSelectableRole(3)}>{bsLang[menuLanguage].employee}</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      label={selectableRole === 1 ? bsLang[menuLanguage].salon_name : bsLang[menuLanguage].salon_code}
                      type="text"
                      variant="standard"
                      className={classes.text_box}
                      onChange={(e) => setSalonNameOrCode(e.target.value)}
                    />
                    <TextField
                      label={bsLang[menuLanguage].admin_email}
                      type="text"
                      variant="standard"
                      className={classes.text_box}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                      className={classes.button_witout_icon}                      
                      variant="contained"
                      onClick={goToCreateUser}
                    >{bsLang[menuLanguage].save}
                    </Button>                    
                    <Button
                      className={classes.button_witout_icon}                      
                      variant="contained"
                      onClick={() => setNewUserMode(false)}
                    >{bsLang[menuLanguage].cancel}
                    </Button>
                    </>
                    :
                    <>
                    <Button
                      className={classes.button_witout_icon}
                      variant="contained"
                      onClick={() => goToGetUser()}
                    >{empCode === '' ? bsLang[menuLanguage].show_user_info : bsLang[menuLanguage].hide_user_info}
                    </Button>
                    {(manCode !== '') && 
                      <Box component="span" className={classes.bs_user_info}>{bsLang[menuLanguage].man_code  + ': ' + manCode}</Box>
                    }
                    {(empCode !== '') && 
                      <Box component="span" className={classes.bs_user_info}>{bsLang[menuLanguage].emp_code  + ': ' + empCode}</Box>
                    }
                    <TextField
                      label={bsLang[menuLanguage].admin_email}
                      type="text"
                      variant="standard"
                      className={classes.text_box}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                      className={classes.button_witout_icon}                      
                      variant="contained"
                      onClick={goToDeleteUser}
                    >{bsLang[menuLanguage].leave_the_group}
                    </Button>  
                    <Button
                      className={classes.button_witout_icon}                      
                      variant="contained"
                      onClick={() => setNewUserMode(false)}
                    >{bsLang[menuLanguage].close}
                    </Button>
                    </>
                    }
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{height: '4px', margin: '8px 2px 14px 2px', background: themeHlp().palette.primary.gradient}} />
                </Grid>
              </>
              }
              {contentAddMode &&
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4} style={{padding: 0}}>
                <Box sx={{my: 2}}>
                  <InputLabel className={classes.input_label} id="beauty-salon-datetime-label">{bsLang[menuLanguage].date}</InputLabel>
                  <input
                    id="appoint"
                    className={classes.datatime_box}
                    type="datetime-local"
                    name="appointdate"
                    onChange={(e) => setAppointmentData({...appointmentData, dateTime: e.target.value})}
                    required
                  />
                  <TextField
                    label={bsLang[menuLanguage].client_name}
                    type="text"
                    variant="standard"
                    className={classes.text_box}
                    onChange={(e) => setAppointmentData({...appointmentData, clientName: e.target.value})}
                  />
                  <TextField
                    label={bsLang[menuLanguage].phone_number}
                    type="text"
                    variant="standard"
                    className={classes.text_box}
                    onChange={(e) => setAppointmentData({...appointmentData, cellphone: e.target.value})}
                  />
                  <TextField
                    label={bsLang[menuLanguage].action}
                    type="text"
                    variant="standard"      
                    className={classes.text_box}
                    onChange={(e) => setAppointmentData({...appointmentData, action: e.target.value})}
                  />
                  <Button
                    className={classes.button_witout_icon}
                    variant="contained"
                    onClick={() => {goToCreateAppointment()}}
                  >{bsLang[menuLanguage].save}
                  </Button>
                  <Button
                    className={classes.button_witout_icon}
                    variant="contained"
                    onClick={() => setContentAddMode(false)}
                  >{bsLang[menuLanguage].cancel}
                  </Button>
                </Box>
              </Grid>                   
              }
              {calculateMode &&
              <Grid item xs={12} sm={8} md={6} lg={4} xl={4} style={{padding: 0}}>
                <Box sx={{my: 2}}>
                <Box>
                  <InputLabel className={classes.input_label} id="beauty-salon-start-date-label">{bsLang[menuLanguage].start_date}</InputLabel>
                  <input
                    className={classes.datatime_box}                   
                    type="date"
                    value={appointmentCalculationData.startDateTime}
                    onChange={(e) => setAppointmentCalculationData({...appointmentCalculationData, startDateTime: e.target.value})}
                    required
                  />
                  </Box>
                  <Box>
                  <InputLabel style={{marginTop: '3.4px'}} className={classes.input_label} id="beauty-salon-end-date-label">{bsLang[menuLanguage].end_date}</InputLabel>
                  <input
                    className={classes.datatime_box}
                    type="date"
                    value={appointmentCalculationData.endDateTime}
                    onChange={(e) => setAppointmentCalculationData({...appointmentCalculationData, endDateTime: e.target.value})}
                    required
                  />    
                  </Box>             
                  <Button
                    className={classes.button_witout_icon}
                    variant="contained"
                    onClick={() => goToCalculateTransactions()}
                  >{bsLang[menuLanguage].calculate}
                  </Button>
                  <Box component="span" className={classes.bs_user_info}>{bsLang[menuLanguage].quantity  + ': ' + calculationInfo.totalQuantity}</Box>
                  <Box component="span" className={classes.bs_user_info}>{bsLang[menuLanguage].total_amount  + ': ' + calculationInfo.totalAmount + ' '}դր․</Box>
                  <Button
                    className={classes.button_witout_icon}
                    variant="contained"
                    onClick={() => setCalculateMode(false)}
                  >{bsLang[menuLanguage].close}
                  </Button>
                </Box>
              </Grid>                   
              }
              {doSearchMode &&
              <>
                <Grid item xs={12} sm={8} md={6} lg={4} xl={4} style={{padding: 0}}>
                  <Box style={{ position: 'relative'}}>               
                    <TextField
                      id="searchField"
                      label={`${bsLang[menuLanguage].search}...`}
                      type="text"
                      variant="standard"
                      className={classes.text_box_icon}                      
                      onChange={(e) => {searchKeyword.current = e.target.value; noteSearch()}}
                    />
                    <i className={classes.search_icon}><SearchIcon /></i>
                    <Button
                      className={classes.button_witout_icon}                      
                      variant="contained"
                      onClick={() => noteSearch()}
                    >{bsLang[menuLanguage].search}
                    </Button>
                    <Button
                      className={classes.button_witout_icon}
                      variant="contained"
                      onClick={() => searchModeFnc()}
                    >{bsLang[menuLanguage].close}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{height: '4px', margin: '8px 2px 14px 2px', background: themeHlp().palette.primary.gradient}} />
                </Grid>
              </>
              }
              {notes.map((data, key) => {
                return (
                  <Grid key={key+1} item xs={12} style={{padding: '0px 0px'}}>
                    <div>{data}
                      <Pagination
                        count={Math.ceil(parseInt(data.notesQty)/rowCount)}
                        size="medium"
                        page={page}
                        variant="outlined"
                        shape="rounded"
                        onChange={paginationChange}
                        style={{visibility: 'hidden'}}
                      />
                    </div>
                  </Grid>
                )
                })
              }

              <Dialog
                fullWidth={true}
                open={openAppointmentDialog}
                onClose={() => {handleCloseAppointmentDialog(); setAppointmentApprovalProcess(false)}}
                maxWidth="xs"
                PaperProps={{
                  component: 'form',
                  onSubmit: (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());
                    const email = formJson.email;
                    handleCloseAppointmentDialog();
                  },
                }}
              >     
                <DialogContent>
                  <Box>
                    <Grid container style={{ marginTop:'4px'}}>
                      <Grid container spacing={0} className={classes.login_box}>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" component="span" className={classes.dialog_text_title} gutterBottom>Case Id</Typography>
                          <TextField
                            type="text"
                            size="small"
                            disabled
                            variant="standard"
                            className={classes.dialog_text_box}
                            InputProps={{style: {fontSize: '0.875rem'}}}
                            value={currentNote.caseId}                        
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" component="span" className={classes.dialog_text_title} gutterBottom>{bsLang[menuLanguage].date}</Typography>
                          <TextField
                            type="text"
                            size="small"
                            disabled
                            variant="standard"
                            className={classes.dialog_text_box}
                            InputProps={{style: {fontSize: '0.875rem'}}}
                            value={new Date(currentNote.appointmentDate).toLocaleString(bsLang[menuLanguage].language_country_code, {timeZone: timeZone,
                              dateStyle: 'full',//full, long, medium, short
                              timeStyle: 'short', //full, long, medium, short
                              hourCycle: 'h24', //h12, h24
                            })}                        
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" component="span" className={classes.dialog_text_title} gutterBottom>{bsLang[menuLanguage].client_name}</Typography>
                          <TextField                        
                            type="text"
                            size="small"
                            disabled={(currentNote.role === 1 || currentNote.role === 2) ? false : true}
                            variant="standard"
                            className={classes.dialog_text_box}
                            InputProps={{style: {fontSize: '0.875rem'}}}
                            value={currentNote.clientName}
                            onChange={(e) => {(currentNote.role === 1 || currentNote.role === 2) && setCurrentNote({...currentNote, clientName: e.target.value})}}
                          />
                        </Grid>
                        {(currentNote.role === 1 || currentNote.role === 2) &&
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" component="span" className={classes.dialog_text_title} gutterBottom>{bsLang[menuLanguage].phone_number}</Typography>
                          <TextField                        
                            type="text"
                            size="small"                        
                            variant="standard"
                            className={classes.dialog_text_box}
                            InputProps={{style: {fontSize: '0.875rem'}}}
                            value={currentNote.phoneNumber}
                            onChange={(e) => {(currentNote.role === 1 || currentNote.role === 2) && setCurrentNote({...currentNote, phoneNumber: e.target.value})}}
                          />                      
                        </Grid>
                        }                
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" component="span" className={classes.dialog_text_title} gutterBottom>{bsLang[menuLanguage].action}</Typography>
                          <TextField                        
                            type="text"
                            size="small"
                            disabled={(currentNote.role === 1 || currentNote.role === 2) ? false : true}
                            variant="standard"
                            className={classes.dialog_text_box}
                            InputProps={{style: {fontSize: '0.875rem'}}}
                            value={currentNote.action}
                            onChange={(e) => {(currentNote.role === 1 || currentNote.role === 2) && setCurrentNote({...currentNote, action: e.target.value})}}
                          />                      
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" component="span" className={classes.dialog_text_title} gutterBottom>{bsLang[menuLanguage].sum}</Typography>
                          <TextField
                            type="number"
                            size="small"
                            disabled = {((currentNote.role === 1 || currentNote.role === 2) || currentNote.appointmentStatus === 'planning') ? false : true}
                            inputRef={sumRef}
                            variant="standard"
                            className={classes.dialog_text_box}
                            InputProps={{style: {fontSize: '0.875rem'}}}
                            value={currentNote.sum}
                            onChange={(e) => setCurrentNote({...currentNote, sum: e.target.value})}
                          />
                          {appointmentApprovalProcess &&  <Typography variant="subtitle2" component="span">{currentNote.role === 3 ? bsLang[menuLanguage].warning_message_before_confirmation_user : bsLang[menuLanguage].warning_message_before_confirmation_admin}</Typography>}
                          {appointmentCancelProcess && <Typography variant="subtitle2" component="span">{currentNote.role === 3 ? bsLang[menuLanguage].warning_message_before_cancel_user : bsLang[menuLanguage].warning_message_before_cancel_admin}</Typography>}
                        </Grid>                 
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions>          
                  <Grid container style={{ margin:'0px 14px'}}>
                    <Grid container spacing={0} className={classes.login_box}>
                      <Grid item xs={12}>
                        {(!appointmentCancelProcess && ((!appointmentApprovalProcess && currentNote.appointmentStatus === 'planning' && currentNote.role === 3) || (!appointmentApprovalProcess && (currentNote.role === 1 || currentNote.role === 2)))) &&
                          <Button
                            className={classes.button_witout_icon}
                            variant="contained"
                            onClick={() => {sumRef.current.focus(); sumRef.current.style.border = '2px solid ' + themeHlp().palette.error.main; setAppointmentApprovalProcess(true)}}
                          >
                            {bsLang[menuLanguage].save}
                          </Button>
                        }
                        {(!appointmentApprovalProcess && ((!appointmentCancelProcess && currentNote.appointmentStatus === 'planning' && currentNote.role === 3) || (!appointmentCancelProcess && (currentNote.role === 1 || currentNote.role === 2)))) &&
                          <Button
                            className={classes.button_witout_icon}
                            variant="contained"
                            onClick={() => {setAppointmentCancelProcess(true)}}
                          >
                            {bsLang[menuLanguage].invalidate}
                          </Button>
                        }
                        {(!appointmentCancelProcess && (((appointmentApprovalProcess && currentNote.appointmentStatus === 'planning') && currentNote.role === 3) || (appointmentApprovalProcess && (currentNote.role === 1 || currentNote.role === 2)))) &&
                          <Button
                            className={classes.button_witout_icon}
                            variant="contained"
                            onClick={() => goToUpdateAppointment(currentNote.caseId, 'approve')}
                          >
                            {bsLang[menuLanguage].confirm}
                          </Button>
                        }
                        {(!appointmentApprovalProcess &&(((appointmentCancelProcess && currentNote.appointmentStatus === 'planning') && currentNote.role === 3) || (appointmentCancelProcess && (currentNote.role === 1 || currentNote.role === 2)))) &&
                          <Button
                            className={classes.button_witout_icon}
                            variant="contained"
                            onClick={() => goToUpdateAppointment(currentNote.caseId, 'invalidate')}
                          >
                            {bsLang[menuLanguage].invalidate}
                          </Button>
                        }
                        <Button 
                          className={classes.button_witout_icon}
                          variant="contained"
                          onClick={() => {handleCloseAppointmentDialog(); setAppointmentApprovalProcess(false); setAppointmentCancelProcess(false);}}
                        >
                          {bsLang[menuLanguage].close}
                        </Button>
                      </Grid>  
                    </Grid> 
                  </Grid>
                </DialogActions>
              </Dialog>

              <Dialog
                open={openAlertDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpenAlertDialog(false)}       
                aria-describedby="alert-dialog-slide-description"
                fullWidth
              >
                <DialogTitle style={{background: themeHlp().palette.primary.gradient, margin: 0}}></DialogTitle>
                <DialogContent>          
                  <DialogContentText id="alert-dialog-slide-description" style={{textAlign: 'center',}}>          
                  <ErrorIcon style={{color: themeHlp().palette.error.main, marginRight: '6px' }} />
                    {alertMessage}           
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button 
                    className={classes.button_small_witout_icon}
                    style={{width:'50%'}}
                    variant="contained"
                    onClick={() => setOpenAlertDialog(false)}
                  >{bsLang[menuLanguage].close}
                  </Button>
                </DialogActions>
              </Dialog>

            </Grid>
          </Grid>          
        </Box>
      </DialogContent>
    </Dialog>
  </>
)
}
 
export default BeautySalonAppointmentApp;
