import React, { useLayoutEffect, useState, useEffect, useTransition } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelSharpIcon from '@material-ui/icons/Close';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import BorderInnerIcon from '@material-ui/icons/BorderInner';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SearchIcon from '@material-ui/icons/Search';
import { styled } from '@material-ui/core/styles';
import { makeStyles} from '@material-ui/core/styles';
import { themeHlp }  from '../Helper/ThemeHelper.js';
import  appResizeWindowHlp  from '../Helper/AppResizeWindowHelper.js';
import { gettopicnotesSrv } from '../AdtService/GetTopicNotesService.js';
import { getcontentnotesSrv } from '../AdtService/GetContentNotesService.js';
import { searchtopicnotesSrv } from '../AdtService/SearchTopicNotesService.js';
import { searchcontentnotesSrv } from '../AdtService/SearchContentNotesService.js';
import { addtopicnotesSrv } from '../AdtService/AddTopicNotesService.js';
import { edittopicnotesSrv } from '../AdtService/EditTopicNotesService.js';
import { deletetopicnotesSrv } from '../AdtService/DeleteTopicNotesService.js';
import { addcontentnotesSrv } from '../AdtService/AddContentNotesService.js';
import { editcontentnotesSrv } from '../AdtService/EditContentNotesService.js';
import { downwardcontentnotesSrv } from '../AdtService/DownwardContentNotesService.js';
import { upwardcontentnotesSrv } from '../AdtService/UpwardContentNotesService.js';
import { deletecontentnotesSrv } from '../AdtService/DeleteContentNotesService.js';
import { addbookmarknotesSrv } from '../AdtService/AddBookmarkNotesService.js';
import { getbookmarknotesSrv } from '../AdtService/GetBookmarkNotesService.js';
import { updatebookmarknotesSrv } from '../AdtService/UpdateBookmarkNotesService.js';
import { deletebookmarknotesSrv } from '../AdtService/DeleteBookmarkNotesService.js';
import { createmvsfileSrv } from '../Service/CreateMvsFileService.js';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),    
    minWidth: 180,
    color: themeHlp().palette.blackText.light,
    backgroundColor: themeHlp().palette.secondary.light,  
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      width: '380px',
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        color: themeHlp().palette.blackText.main,        
      },
    },
    
  }
}));

const useStyles = makeStyles((theme) => ({
  menu_box:{
    width:'100%', 
    height:'340px', 
    margin:'auto auto', 
    background: 'linear-gradient(135deg, rgb(38, 93, 140) 0%, rgb(38, 93, 140) 15%, rgb(0, 140, 183) 50%)', 
    opacity:'1', 
    borderRadius:'15px', 
    border:'4px solid #f5a33e'
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: themeHlp().palette.secondary.light,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  sub_headline: {
    whiteSpace: 'nowrap', display: 'block', overflow: 'hidden', textOverflow: 'ellipsis'
  },
  doc_reader: {
    width: '100%',
    border: '0px'
  },
  user_avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: 'auto',
    cursor: 'pointer'
  },
  dialog_action: {
    padding: '0px',
  },
  dialog_content: {
    padding: '0px',
  },
  textarea_topic_view_box: {
    width: 'auto',
    fontFamily: 'inter',
    border: '0',  
    marginTop:'4px',    
    paddingLeft: '10px',
    backgroundColor: 'transparent',    
    resize: 'none',
    color:  themeHlp().palette.whiteText.main,
    opacity: 1,
    height: '24px !important',
    overflow: 'hidden',
    float: 'left',
    cursor: 'pointer'
  },
  textarea_topic_edit_box: {
    width: '100%',
    fontFamily: 'inter',
    border: '0',  
    marginTop:'4px',
    backgroundColor: themeHlp().palette.whiteText.main,
    resize: 'none',
    color:  themeHlp().palette.blackText.main,
    opacity: 1,
    height: '24px !important',
    overflow: 'hidden',
    '&:hover': {
      border: '1px solid ' + '#008cb7',
      borderStyle: 'none none solid none',
    },
    '&:focus': {
      outline: 'none !important'
    },
  },
  textarea_content_view_box_url: {
    textDecoration: 'none',
    color: themeHlp().palette.primary.dark,
    padding: '2px',
    marginTop: '4px',
    display: 'inline-block'
  },
  textarea_content_view_box: {
    width: '100%',
    fontFamily: 'Helvetica',
    border: '0',  
    marginTop:'4px',    
    backgroundColor: '#f7f4b5',    
    resize: 'none',
  },
  textarea_content_edit_box: {
    width: '100%',
    fontFamily: 'Helvetica',
    border: '0',  
    marginTop:'4px',
    backgroundColor: themeHlp().palette.whiteText.main,
    resize: 'none',
    '&:hover': {      
      border: '1px solid ' + '#008cb7',
      borderStyle: 'none none solid none',
    },
    '&:focus': {      
      outline: 'none !important'
    },
  },
  iconbutton_topic: {
    width:'22px',
    height: '22px',    
    color: '#5d4235',
    '&:hover' : {
      color: '#5d4235',
    },
    '&:active' : {
      color: '#5d4235',
    }
  },
  iconbutton_content: {
    width:'22px',
    height: '22px',    
    color: themeHlp().palette.primary.light,
    '&:hover' : {
      color: themeHlp().palette.primary.main
    },
    '&:active' : {
      color: themeHlp().palette.primary.dark,    
    }
  },
  file_icon: {
    width: '24px',
    height: '24px',     
    backgroundRepeat: 'no-repeat',
    backgroundSize: '22px',
    backgroundPosition: '0px 2px',
    padding: '0 24px 0 0',
  },
  upload_file: {
    display: 'none'
  },
  linear_progress_box: {
    width: '200px',
    margin: 'auto auto',
    '& .MuiLinearProgress-root': {
      backgroundColor: themeHlp().palette.secondary.light
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: themeHlp().palette.primary.light
    }   
  },
  font_badge: {
    '& .MuiBadge-colorPrimary': {
      background: themeHlp().palette.primary.gradient,
      color: themeHlp().palette.secondary.light
    }
  },
  topic_number_badge: {
    '& .MuiBadge-colorError': {
      background: 'transparent',
      color: '#5d4235'
    }
  },
  content_number_badge: {
    '& .MuiBadge-colorError': {
      background: 'transparent',
      color: '#e94424'
    }
  },
  notes_accordion: {    
    background: 'linear-gradient(110deg, rgba(93,66,53,1) 0%,  rgba(200,138,112,1) 100%)', 
    boxShadow: 'none',
    marginBottom: '0 !important' ,
    '& .MuiAccordion-root': {
      marginBottom: 0
    }
  },
  notes_accordion_summary: {
    margin: '0 0 8px 0', 
    height: '20px',
    '& .MuiAccordionSummary-content': {
      display: 'block'
    },
    '& .MuiAccordionSummary-expandIcon': {
      display: 'none'
    }
  },
  notes_accordion_details: {
    background: '#f7f4b5', 
    padding: '0'
  },
  text_box: {
    width:'100%',
    margin: '4px 0',
    borderBottom: '1px solid ' + themeHlp().palette.primary.light,
    fontSize: '20px', 
    '& .MuiInputLabel-root': {
      color: themeHlp().palette.blackText.light,
    },
    '& .MuiInputLabel-shrink': {
      color: themeHlp().palette.primary.light,      
    },
    '&:hover': {
      border: '1px solid ' + themeHlp().palette.primary.light,
      borderStyle: 'none none solid none',
    }
  },
  text_box_icon: {
    width:'100%',
    margin: '4px 0',
    borderBottom: '1px solid ' + themeHlp().palette.primary.light,
    padding: '0px 24px',
    fontSize: '20px', 
    '& .MuiInputLabel-root': {
      color: themeHlp().palette.blackText.light,
      padding: '0px 24px'
    },
    '& .MuiInputLabel-shrink': {
      color: themeHlp().palette.primary.light,      
    },
    '&:hover': {
      border: '1px solid ' + themeHlp().palette.primary.light,
      borderStyle: 'none none solid none',
    }
  },
  search_icon: {
    color: themeHlp().palette.blackText.light,
    position:'absolute', 
    top: '24px', 
    left: '0px'
  },
  menu_linear_progess: {
    background: 'linear-gradient(110deg, rgba(93,66,53,1) 0%,  rgba(200,138,112,1) 100%)',
    '& .MuiLinearProgress-bar' : {
      background: themeHlp().palette.primary.light
    }
  }
}));

const NotesApp = (props) => {

  const classes = useStyles();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [open, setOpen] = React.useState(false);
  const [toolbarHidden, setToolbarHidden] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [topicEditModeId, setTopicEditModeId] = useState('');
  const [contentEditModeId, setContentEditModeId] = useState('');
  const [topicAskForDelete, setTopicAskForDelete] = useState(false);
  const [contentAskForDelete, setContentAskForDelete] = useState(false);
  const [newNotebookMode, setNewNotebookMode] = useState(false);
  const [notesMode, setNotesMode] = useState(0);
  const [contentAddMode, setContentAddMode] = useState(false);
  const [doSearchMode, setDoSearchMode] = useState(false);
  const [notes, setNotes] = useState([]);
  const [allInfo, setAllInfo] = useState([]);
  const [openedNoteNum, setOpenedNoteNum] = useState(null);  
  const [bookmarkNote, setBookmarkNote] = useState(0);
  const [topicId, setTopicId] = useState(null);
  const [bookmarkInUse, setBookmarkInUse] = useState({trueBookmark: false, lastOpened: false});
  const [clickedBookmarkMenu, setClickedBookmarkMenu] = useState(null);
  const [isPending, startTransition] = useTransition();
  let debounceSearching;

  const [newNotebookName, setNewNotebookName] = useState('');
  const [currentNotebookName, setCurrentNotebookName] = useState('');
  const [newContentValue, setNewContentValue] = useState('');
  const [contentValue, setContentValue] = useState('');

  const [contentFontSize, setContentFontSize] = useState(16);
  const [page, setPage] = useState(1);
  const rowCount = 15;
  const titleDisplaySize = 446;

  const [anchorFontSizeEl, setAnchorFontSizeEl] = React.useState(null);
  const openFontSize = Boolean(anchorFontSizeEl);
  const openFontSizeMenu = (event) => {
    setAnchorFontSizeEl(event.currentTarget);
  };
  const closeFontSizeMenu = () => {
    setAnchorFontSizeEl(null);
  };

  const [anchorBookmarkEl, setAnchorBookmarkEl] = React.useState(null);
  const openBookmark = Boolean(anchorBookmarkEl);
  const openBookmarkMenu = (event) => {
    setAnchorBookmarkEl(event.currentTarget);
  };
  const closeBookmarkMenu = () => {
    setAnchorBookmarkEl(null);
  };

  const handleClickOpen = () => {

    setErrorMessage('');
    setNotes([]);
    getBookmarkFnc(false, 'autoload');

    if(allInfo.length === 0){
      gettopicnotesSrv()
      .then(response => {        
        if(response && response.statusCode === 200){
          notesBuilder(response.data);
        }
      }).catch(err => {
        console.log(err);
      })
  
    }else{
      notesBuilder(allInfo)
    }

    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('notes') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount <2){
      allApps.push('notes');
      setOpen(true);
      
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
        apps: allApps,
        appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount + 1
      }));    
      
      setTimeout(() => {
        appsInWindows('load');
      },100);
    }
  };

  const handleClose = () => {
    setOpen(false);
    if(props.appCloses){
      props.appCloses(true);
    }

    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;
    if(allApps.indexOf('notes') !== -1){
      allApps.splice(allApps.indexOf('notes'), 1);
    }

    localStorage.setItem('movwin', JSON.stringify({
      windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
      apps: allApps,
      appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount - 1
    }));
  };

  const appsInWindows = (event) => {
    if(event === 'change'){
      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode < 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode + 1,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }else if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode === 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: 0,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }

      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).appCount === 0){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: 1
        }));
      }
    }

    setWindowWidth(windowWidth - 1);  
    appResizeWindowHlp('notesDialogBox');
  }

  /*
  function isValidHttpUrlFnc(string) {
    try {
      const url = new URL(string);
      return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (err) {
      return false;
    }
  }*/


  /*
    let arr = {
      str: [],
      num: [],
      urlExists: false
    }

    const [mek, setMek] = useState(false);

 function showValidUrlFnc(string) {


      const chars = string.split(' ');	
      arr = {
        str: [],
        num: [],
        urlExists: false
      };
   		for(let i=0; i< chars.length; i++){
         
         
          
          try {
            const url = new URL(chars[i]);
            arr.str.push(chars[i])
            arr.num.push(true)
            arr.urlExists = true
          } catch (err) {
            arr.str.push(chars[i])
            arr.num.push(false)
          }          
        }

console.log(arr)
        setMek(true)

    return (
      <a href="https://yahoo.com">Yhaoo</a>
    )
  }

*/


/*const str = ' http://movesas.com 56';

isValidHttpUrlFnc(str)
console.log(arr)*/  
  

  const loadNotebooksFnc = () => {
    setOpenedNoteNum(null);
    setAllInfo([]);
    setContentFontSize(16);
    setPage(1);
    setNewNotebookMode(false); 
    setDoSearchMode(false);
    setNotesMode(0);
    setTopicEditModeId('');
    setContentEditModeId(''); 
    setTopicAskForDelete(false);
    setContentAskForDelete(false);
    setContentAddMode(false); 
    setNewNotebookName('');
    setCurrentNotebookName('');
    setNewContentValue('');
    setContentValue('');
  }

  const addBookmarkFnc = (state) => {
    setClickedBookmarkMenu('add');
    startTransition(() => { 
      const addbookmarknotesParams = {
          topic_id: topicId,
          bookmark_note: bookmarkNote,
          bookmark_page: page,
          bookmark_state: state
      }
      addbookmarknotesSrv(addbookmarknotesParams)
      .then(response => {
        if(response && response.statusCode === 201){
          goToGetContent(topicId, bookmarkNote);
          closeBookmarkMenu();
          setClickedBookmarkMenu(null);
        }
      }).catch(err => {
        console.log(err);
      })
    })
  }

  const getBookmarkFnc = (state, loading, loadType) => {   
    if(loadType === 'load'){
      setClickedBookmarkMenu('load');
    }else if(loadType === 'loadlastopened'){
      setClickedBookmarkMenu('loadlastopened');
    }
      
    startTransition(() => { 
      getbookmarknotesSrv(state)
      .then(response => {
        if(response && response.statusCode === 200){
          if(loading === 'autoload'){       
            setBookmarkInUse({trueBookmark: response.data.trueBookmark, lastOpened: response.data.lastOpened});
          }else if(loading === 'clickload'){                      
            goToGetContent(response.data.topicId, response.data.bookmarkNote);
            setPage(response.data.bookmarkPage);            
            closeBookmarkMenu();
            setClickedBookmarkMenu(null);
          }
        }
      }).catch(err => {
        console.log(err);
      });
    })
  }

  const updateBookmarkFnc = (state, currentPage) => {
    setClickedBookmarkMenu('update');
    startTransition(() => { 
      const updatebookmarknotesParams = {
        topic_id: topicId,
        bookmark_note: bookmarkNote,
        bookmark_page: currentPage,
        bookmark_state: state
      }
      updatebookmarknotesSrv(updatebookmarknotesParams)
      .then(response => {
        if(response && response.statusCode === 200){
          goToGetContent(topicId, bookmarkNote);
          closeBookmarkMenu();
          setClickedBookmarkMenu(null);
        }
      }).catch(err => {
        console.log(err);
      })
    })
  }

  const deleteBookmarkFnc = (state) => {
    setClickedBookmarkMenu('delete');
    startTransition(() => { 
      deletebookmarknotesSrv(state)
      .then(response => {
        if(response && response.statusCode === 200){
          goToGetContent(topicId, bookmarkNote);
          closeBookmarkMenu();
          setClickedBookmarkMenu(null);
        }
      }).catch(err => {
        console.log(err);
      });
    })
  }

  const editNoteFnc = (topicId, caseId) => {
    if(caseId === undefined){
      goToGetContent(topicId, openedNoteNum);
    }
   
    setContentEditModeId(caseId); 
    setTopicEditModeId(''); 
    setTopicAskForDelete(false); 
    setContentAskForDelete(false);  
  }

  const searchModeFnc = () => {
    setNewNotebookMode(false); 
    
    if(!doSearchMode){
      setDoSearchMode(true);
    }else{
      setDoSearchMode(false); 
    }    
    //document.getElementsByClassName('dialog_content')[0].scrollTop = 0;
  }
  

  const searchNote = (value) => {
    setNotes([]);

    const searcnoteParams = {
      keyword: value
    }
    searchtopicnotesSrv(searcnoteParams)
    .then(response => {
      if(response && response.statusCode === 200){
        setAllInfo(response.data);
        notesBuilder(response.data);
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const noteSearch = (value) => {
    if(value !== ''){
      if(debounceSearching !== undefined){
        clearTimeout(debounceSearching);
      }
      debounceSearching = setTimeout(function() {
        searchNote(value);
      },1200);
    }
  }

  ///////////////////////////////////////////////
  const goToGetContent = (topicId, id) => {

    const headerLoading = document.querySelectorAll('.header_loading');
    



   // debugger
    if(topicEditModeId === ''){
      //console.log(headerLoading)

      headerLoading[id].style.display = 'block';
      
    }

    setBookmarkNote(id);
    setTopicId(topicId);
    
    let keyword = document.getElementById('searchField');

    //if(doSearchMode === false || keyword === null){     
  //  console.log(keyword) 
      if(keyword === null){      
      const getcontentnotesParams = {
        topic_id: topicId
      }
      getcontentnotesSrv(getcontentnotesParams)
      .then(response => {      
        if(response && response.statusCode === 200){   
        //  console.log(response.data)       
          setAllInfo(response.data);
          setOpenedNoteNum(id);    
       //   console.log('stex') 
        //  headerLoading[id].style.display = 'none';
      //  for(let i=0; i<headerLoading.length; i++){
      //    console.log('stex2')
          headerLoading[id].style.display = 'none';
      //  }
        }
      }).catch(err => {
        console.log(err);
      })
    }else{      
     
      const searchcontentnotesParams = {
        topicId: topicId,
        keyword: keyword.value
      }
      searchcontentnotesSrv(searchcontentnotesParams)
      .then(response => {      
        if(response && response.statusCode === 200){
        setAllInfo(response.data);
          setOpenedNoteNum(id);    
        //  for(let i=0; i<headerLoading.length; i++){
         //   console.log('stex3')
            headerLoading[id].style.display = 'none';
        //  }    
        }
      }).catch(err => {
        console.log(err);
      })
    }    
  }

  ///////////////////////////////////////////////
  const goToCreateMvs = () => {
    const notesForMvs = [];

    notesForMvs.push('<table style="width:500px; border-collapse: collapse;">');
    for(let i = parseInt(openedNoteNum); i<parseInt(openedNoteNum+1); i++){
      notesForMvs.push('<tr><th style="font-size:16px; font-weight:bold; border-radius:30px 30px 0px 0px; padding-left:20px; text-align:left; height: 64px; background: linear-gradient(110deg, rgba(93,66,53,1) 0%,  rgba(200,138,112,1) 100%); color: white">' + allInfo[0].topics[parseInt(openedNoteNum)].topic +'</th></tr>');
      for(let j = 0; j<allInfo[0].contents[i].length; j++){        
        notesForMvs.push('<tr><td style="font-size:16px; background-color:#f7f4b5; height: 34px; padding: 4px 10px; border-bottom:2px solid rgb(129, 181, 157)">' + allInfo[0].contents[i][j].content + '</td></tr>');
      }
      notesForMvs.push('<tr><td style="background-color:#f7f4b5; height: 66px; padding: 12px 10px 8px 10px; border-radius:0 0 8px 8px; box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px"></td></tr>');
    }
    notesForMvs.push('</table>');
    
    const createmvsfileParams = {
      item_location: window.location.pathname,
      item_ext: 'mvsa',
      content: notesForMvs.join('')
    }    
    createmvsfileSrv(createmvsfileParams)
    .then(response => {      
      if(response && response.statusCode === 201){
        window.location.reload();
      }else if(response.error && response.error.statusCode === 400){
        setErrorMessage(response.error.message);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const goToCreateNotebook = () => {

    const addtopicnotesParams = {
      topic: newNotebookName
    }
    addtopicnotesSrv(addtopicnotesParams)
    .then(response => {
      if(response && response.statusCode === 201){
        setAllInfo([]);
        setNewNotebookMode(false);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const goToEditNotebook = (id, caseId) => {

    const updatedTopic = [
      {
        "topics": allInfo[0].topics,
        "contents": allInfo[0].contents
      }
    ];
    updatedTopic[0].topics[id].topic = currentNotebookName;

    const edittopicnotesParams = {
      caseId: caseId,
      topic: currentNotebookName
    }
    edittopicnotesSrv(edittopicnotesParams)
    .then(response => {
      if(response && response.statusCode === 200){
        setTopicEditModeId('');
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const goToDeleteNotebook = (topicId) => {
    const deletetopicnotesParams = {
      id: topicId
    }
    deletetopicnotesSrv(deletetopicnotesParams)
    .then(response => {
      if(response && response.statusCode === 200){
        setAllInfo([]);
      }
    }).catch(err => {
      console.log(err);
    })
  }
  ////////////////////////////////////////////////////////////////


  const goToAddContent = (topicId, id) => {
    
    const updatedContent = [
      {
        "topics": allInfo[0].topics,
        "contents": allInfo[0].contents        
      }
    ];
    updatedContent[0].contents[id].push({
      topicId: topicId,
      content: newContentValue                
    })      

    const addcontentnotesParams = {
      topicId: topicId,
      content: newContentValue
    }
    addcontentnotesSrv(addcontentnotesParams)
    .then(response => {
      if(response && response.statusCode === 201){
        setContentAddMode(false);
    //    setAllInfo(updatedContent);
        setTimeout(() => {
          goToGetContent(topicId, id);
        },400)
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const goToEditContent = (id, contentNumber, caseId) => {

    const updatedContent = [
      {
        "topics": allInfo[0].topics,
        "contents": allInfo[0].contents        
      }
    ];
    updatedContent[0].contents[id][contentNumber].content = contentValue;

    const editcontentnotesParams = {
      caseId: caseId,
      content: contentValue
    }
    editcontentnotesSrv(editcontentnotesParams)
    .then(response => {
      if(response && response.statusCode === 200){
        setContentEditModeId('');
        //setAllInfo(updatedContent);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const goToDeleteContent = (id, contentNumber, caseId) => {

    const updatedContent = allInfo;
    updatedContent[0].contents[id].splice(contentNumber, 1);
   // setAllInfo([]);

    const deletecontentnotesParams = {
      id: caseId
    }
    deletecontentnotesSrv(deletecontentnotesParams)
    .then(response => {
      if(response && response.statusCode === 200){   
        setContentEditModeId('');
    //    setAllInfo(updatedContent);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const goToMoveDownwardContent = (id, contentNumber, caseId) => {
   
    const downwardcontentnotesParams = {
      id: caseId
    }
    downwardcontentnotesSrv(downwardcontentnotesParams)
    .then(response => {
      if(response && response.statusCode === 200){   
        let updatedContent = allInfo;
        [updatedContent[0].contents[id][contentNumber], updatedContent[0].contents[id][contentNumber + 1]] = [updatedContent[0].contents[id][contentNumber + 1], updatedContent[0].contents[id][contentNumber]];

        setContentEditModeId('');
        setNotesMode(2);
      
        setTimeout(() => {
          setNotesMode(1);
        },200);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const goToMoveUpwardContent = (id, contentNumber, caseId) => {

    const upwardcontentnotesParams = {
      id: caseId
    }
    upwardcontentnotesSrv(upwardcontentnotesParams)
    .then(response => {
      if(response && response.statusCode === 200){                
        let updatedContent = allInfo;
        [updatedContent[0].contents[id][contentNumber], updatedContent[0].contents[id][contentNumber - 1]] = [updatedContent[0].contents[id][contentNumber - 1], updatedContent[0].contents[id][contentNumber]];    
        
        setContentEditModeId('');
        setNotesMode(2);

        setTimeout(() => {
          setNotesMode(1);
        },200);
      }
    }).catch(err => {
      console.log(err);
    })
  }
//////////////////////////////////////////////////////////
  const paginationChange = (env, page) => {
    setNotes([]);
    notesBuilder(allInfo);
    setPage(page);

    

    if(bookmarkInUse.lastOpened === true){
      updateBookmarkFnc(false, page);
    }else{
      addBookmarkFnc(false);
    }

    
  };

  function notesBuilder(data) {

    for(let i=0; i< data[0].topics.length; i++){ 
      setNotes(oldTopics => [...oldTopics,
        <Accordion
          className={classes.notes_accordion}
          expanded={i===openedNoteNum}  
          key={data[0].topics[i].topic}
          style={{borderRadius: '30px 30px 0 0'}}
        >
          <AccordionSummary
            className={classes.notes_accordion_summary}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${i+1}bh-content`}
            id={`panel${i+1}bh-header`}
            style={{borderRadius: '30px 30px 0 0'}}
            onClick={() => goToGetContent(data[0].topics[i].topicId, i)}
          >
            { topicEditModeId === data[0].topics[i].topicId
            ?
              <TextField
                type="text"
                variant="standard"
                defaultValue={data[0].topics[i].topic}
                className={classes.textarea_topic_edit_box}
                onChange={(e) => setCurrentNotebookName(e.target.value)}
              />
            :<>            
              <CircularProgress className="header_loading" style={{float: 'right', display: 'none', color: themeHlp().palette.primary.light}} size={26} thickness={8} />
              <TextareaAutosize
                disabled
                className={classes.textarea_topic_view_box}                
                defaultValue={data[0].topics[i].topic}
                style={{fontSize: '16px', fontWeight: 'bold'}}
              />
              </>
            }            
          </AccordionSummary>
          <AccordionDetails className={classes.notes_accordion_details} style={{fontSize: `${contentFontSize}px`}}>
            <Box style={{position: 'relative', width: '100%'}}>
              {notesMode === 1 &&
                <Box style={{width: '100%', height: '28px', background: '#f7f4b5'}}>
                  {topicEditModeId === data[0].topics[i].topicId
                    ?
                    <>
                      <Box style={{float: 'left', paddingLeft: '10px'}}>
                        <IconButton disabled={currentNotebookName.trim().length > 0 ? false : true} title="Save Notebook Name" size="small" aria-label="directions" style={{marginRight: '30px'}} onClick={() => goToEditNotebook(i, data[0].topics[i].caseId)}>
                          <CheckIcon className={classes.iconbutton_topic} />
                        </IconButton>
                        <IconButton title="Cancel" size="small" aria-label="directions" style={{marginRight: '30px'}} onClick={() => setTopicEditModeId('')}>
                          <ClearIcon className={classes.iconbutton_topic} />
                        </IconButton>
                        {!topicAskForDelete ?
                          <IconButton title="Delete Notebook" size="small" aria-label="directions" style={{}} onClick={() => setTopicAskForDelete(true)}>
                            <DeleteIcon className={classes.iconbutton_topic} />
                          </IconButton>
                          :
                          <>
                            <Button size="small" className={classes.iconbutton_topic} onClick={() => goToDeleteNotebook(data[0].topics[i].topicId)}>Delete</Button>
                            <Button size="small" className={classes.iconbutton_topic} onClick={() => setTopicAskForDelete(false)}>Cancel</Button>
                          </>
                        }
                      </Box>
                    </>
                    :
                    <>             
                      <Box style={{float: 'left', paddingLeft: '10px',}}>            
                      <Badge badgeContent={i+1} color="error" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} className={classes.topic_number_badge} max={150} style={{marginRight: '10px'}}>
                        <IconButton title="Edit Notebook" size="small" aria-label="directions" onClick={() => {setTopicEditModeId(data[0].topics[i].topicId); setContentEditModeId(''); setTopicAskForDelete(false); setContentAskForDelete(false);}}>
                          <EditIcon className={classes.iconbutton_topic} />
                        </IconButton>
                        </Badge>
                      </Box>
                    </>
                  }
                </Box>
              }
              <Box sx={{width: '100%', padding: '4px 10px 28px 10px'}}>            
                {data[0].contents[i].map((data, key) => {                
                  if(
                    (key < rowCount && page === 1) || 
                    ((key >= rowCount && key < rowCount*2) && page === 2) ||
                    ((key >= rowCount*2 && key < rowCount*3) && page === 3) ||
                    ((key >= rowCount*3 && key < rowCount*4) && page === 4) ||
                    ((key >= rowCount*4 && key < rowCount*5) && page === 5) ||
                    ((key >= rowCount*5 && key < rowCount*6) && page === 6) ||
                    ((key >= rowCount*6 && key < rowCount*7) && page === 7) ||
                    ((key >= rowCount*7 && key < rowCount*8) && page === 8) ||
                    ((key >= rowCount*8 && key < rowCount*9) && page === 9) ||
                    ((key >= rowCount*9 && key < rowCount*10) && page === 10)
                  ){
                    return (            
                      <div key={key+1} style={{textAlign: 'left', margin: '4px 0 0 0'}}>                        
                        { contentEditModeId === data.caseId 
                        ?
                        <>
                          <TextareaAutosize                                     
                            className={classes.textarea_content_edit_box}
                            defaultValue={data.content}
                            onChange={(event) => setContentValue(event.target.value)}
                            style={{fontSize: `${contentFontSize}px`, opacity: 1}}                            
                          />
                          <div style={{width: '100%', height: '28px'}}>
                            <IconButton disabled={contentValue.trim().length > 0 ? false : true} title="Save Note" size="small" aria-label="directions" style={{marginRight: '30px'}} onClick={() => goToEditContent(i, key, data.caseId)}>
                              <CheckIcon className={classes.iconbutton_content} />
                            </IconButton>
                            <IconButton title="Cancel" size="small" aria-label="directions" style={{marginRight: '30px'}} onClick={() => setContentEditModeId('')}>
                              <ClearIcon className={classes.iconbutton_content} />
                            </IconButton>
                            <IconButton title="Move Downward" size="small" aria-label="directions" onClick={() => goToMoveDownwardContent(i, key, data.caseId)}>
                              <ArrowDownwardIcon className={classes.iconbutton_content} />
                            </IconButton>
                            <IconButton title="Move Upward" size="small" aria-label="directions" style={{marginRight: '30px'}} onClick={() => goToMoveUpwardContent(i, key, data.caseId)}>
                              <ArrowUpwardIcon className={classes.iconbutton_content} />
                            </IconButton>
                            {!contentAskForDelete 
                              ?
                              <IconButton title="Delete Note" size="small" aria-label="directions" style={{}} onClick={() => setContentAskForDelete(true)}>
                                <DeleteIcon className={classes.iconbutton_content} />
                              </IconButton>
                              :
                              <>
                                <Button size="small" className={classes.iconbutton_content} onClick={() => goToDeleteContent( i, key, data.caseId)}>Delete</Button>
                                <Button size="small" className={classes.iconbutton_content} onClick={() => setContentAskForDelete(false)}>Cancel</Button>
                              </>
                            }
                          </div>
                        </>
                        :
                        <> 
                        {notesMode === 0 && 
                          <TextareaAutosize
                            disabled
                            className={classes.textarea_content_view_box}
                            defaultValue={data.content}                            
                            onChange={(event) => setContentValue(event.target.value)}
                            style={{fontSize: `${contentFontSize}px`, opacity: 1}}
                          />
                        }
                        {notesMode === 1 && 
                        <>
                          <TextareaAutosize
                            disabled
                            className={classes.textarea_content_view_box}
                            defaultValue={data.content}                            
                            onChange={(event) => setContentValue(event.target.value)}
                            style={{fontSize: `${contentFontSize}px`, opacity: 1}}
                          />                          
                            <>
                              <Badge badgeContent={key+1} color="error" className={classes.content_number_badge} max={150} style={{marginRight: '10px', }}>
                                <IconButton title="Edit Note" size="small" aria-label="directions"  onClick={() => {editNoteFnc(data.topicId, data.caseId)}}>
                                  <EditIcon className={classes.iconbutton_content} />
                                </IconButton>
                              </Badge>
                              <Chip 
                                size="small"
                                style={{fontSize: '10px', background: '#f7f4b5', color: '#81b59d', paddingTop: '10px'}}
                                label={data.updatedAt ? new Date(data.updatedAt).toLocaleString('en-US', {timeZone: timeZone,
                                  dateStyle: 'short',//full, long, medium, short
                                  timeStyle: 'medium', //full, long, medium, short
                                  hourCycle: 'h12', //h12, h24
                                }) : ''} />
                            </>
                          </>
                        }    
                        {(notesMode === 2 && (data.content.includes('http://') || data.content.includes('https://'))) &&                             
                          <a href={(data.content.includes('https://') && ((data.content.slice(data.content.indexOf('https://'))).split(' '))[0]) || (data.content.includes('http://') && ((data.content.slice(data.content.indexOf('http://'))).split(' '))[0])} target="_blank">
                            <TextareaAutosize
                              disabled
                              title={(data.content.includes('https://') && ((data.content.slice(data.content.indexOf('https://'))).split(' '))[0]) || (data.content.includes('http://') && ((data.content.slice(data.content.indexOf('http://'))).split(' '))[0])}
                              className={classes.textarea_content_view_box}
                              defaultValue={data.content}
                              onChange={(event) => setContentValue(event.target.value)}
                              style={{fontSize: `${contentFontSize}px`, opacity: 1, textDecoration: 'underline', cursor: 'pointer', color: '#110182'}}
                            />
                          </a>
                        }
                        {(notesMode === 2 && (!data.content.includes('http://') && !data.content.includes('https://'))) &&
                          <TextareaAutosize
                            disabled
                            className={classes.textarea_content_view_box}
                            defaultValue={data.content}
                            onChange={(event) => setContentValue(event.target.value)}
                            style={{fontSize: `${contentFontSize}px`, opacity: 1}}
                          />                          
                        }                                       
                        </>
                        }
                        <Divider style={{  height: '2px', backgroundColor: '#81b59d'}} />
                      </div>                
                    )
                  }          
                })}                
                <div style={{color: '#e94424'}}>
                  {contentAddMode 
                    ?
                    <>
                      <TextareaAutosize                        
                        className={classes.textarea_content_edit_box}
                        defaultValue={data.content}
                        style={{fontSize: `${contentFontSize}px`, opacity: 1}}
                        onChange={(e) => setNewContentValue(e.target.value)}
                      />
                      <IconButton disabled={newContentValue.trim().length > 0 ? false : true} title="Create Note" size="small" aria-label="directions" style={{float:"left", margin: '6px 30px 0 0'}} onClick={() => {goToAddContent(data[0].topics[i].topicId, i)}}>
                        <CheckIcon className={classes.iconbutton_content} />
                      </IconButton>
                      <IconButton title="Cancel" size="small" aria-label="directions" style={{float:"left", margin: '6px 0 0 0', }} onClick={() => setContentAddMode(false)}>
                        <ClearIcon className={classes.iconbutton_content} />
                      </IconButton>
                    </>
                    :
                    <>                  
                      <IconButton title="Add Note" size="small" aria-label="directions" style={{float: 'left', margin: '6px 0 0 0'}} onClick={() => setContentAddMode(true)}>
                        <AddIcon className={classes.iconbutton_content} />
                      </IconButton>
                      <Typography variant="subtitle2" style={{color: themeHlp().palette.blackText.main, textAlign:'left', padding: '16px 0 0 0'}}>{data.content}</Typography>
                    </>
                  }                        
                </div>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      ])
    }
  }

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);      
    }

    window.addEventListener('resize', updateSize);
    updateSize();
   
    return () => {     
      window.removeEventListener('resize', updateSize); 
    }
  },[windowWidth, windowHeight])

  useEffect(() => {
    handleClickOpen();    
    if(!localStorage.getItem('movwin')){
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: 0,
        apps: [],
        appCount: 1
      }));
    }     
    
    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('notes') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount < 2){
      setOpen(true);
      setTimeout(() => {
        const muiBackdrop = document.querySelectorAll('.MuiBackdrop-root');
        let newApp = JSON.parse(localStorage.getItem('movwin')).apps;
        newApp.push('notes');
        
        if(muiBackdrop[2]){
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 2
          })); 
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1]) {          
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 1
          }));        
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1] === undefined) {        
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: [],
            appCount: 0,
          }));        
        }
        appsInWindows('load');
      },100);
    }
  },[
    allInfo,
    openedNoteNum, 
    page,
    topicEditModeId, 
    contentEditModeId, 
    contentAddMode, 
    contentFontSize, 
    notesMode,
    topicAskForDelete,
    contentAskForDelete,
    currentNotebookName,
    newContentValue,
    contentValue    
  ])

return (
  <>
    <Avatar title="Notes" component="span" variant="rounded" alt="Notes" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={handleClickOpen} />
    <Typography title="Notes" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Notes</Typography>
    <Dialog id="notesDialogBox" fullScreen onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        style={{
          padding: '8px 16px',
          color:'#ffffff', 
          background:'linear-gradient(90deg, rgba(38,93,140,1) 0%, rgba(38,93,140,1) 15%, rgba(0,140,183,1) 100%)'
        }}
        id="customized-dialog-title" onClose={handleClose}
      >
        <Avatar component="span" variant="rounded" style={{width:'24px', height: '24px', display: 'inline-block', marginRight: '8px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
        <span>{(windowWidth < titleDisplaySize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0) || (windowWidth < (titleDisplaySize/2) && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) === 0) ? '' : 'Notes'}</span>
        <IconButton
          disabled={document.getElementById('shutdownDialog') ? false : true}
          aria-label="AppsInWindowsIcon"
          onClick={() => appsInWindows('change')}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white',          
            margin:'2px 42px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px',
            opacity: document.getElementById('shutdownDialog') ? '1' : '0.3'
          }}
        >
          <BorderInnerIcon style={{padding: '2px'}} />          
        </IconButton>
        <IconButton 
          aria-label="UnfoldLessMoreIcon"
          title={!toolbarHidden ? 'Hide Toolbar' : 'Show Toolbar'}
          onClick={()=> setToolbarHidden(current => !current)}
          size="small"
          style={{
            width: '18px',
            height: '18px',
            background: themeHlp().palette.blueGrey.dark,
            color:'white',  
            margin:'2px 74px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          {!toolbarHidden ? <UnfoldLessIcon style={{padding: '4px'}} /> : <UnfoldMoreIcon style={{padding: '4px'}} />}
        </IconButton>
        <IconButton 
          aria-label="CancelSharpIcon"
          title="Close"
          onClick={handleClose}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white', 
            margin:'2px 10px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          <CancelSharpIcon style={{padding: '2px'}} /> 
        </IconButton>
      </DialogTitle>
      {!toolbarHidden && 
      <DialogActions id="p0" className={classes.dialog_action}>
        <div id="p1" className={classes.root}>          
          <List style={{ width: '100%', height: '64px', padding: '0px', overflowY: 'auto'}}>
            <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label htmlFor="contained-button-file-image-browse">
                <Avatar title="New Notebook" component="span" variant="rounded" alt="New Notebook" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes/notes_create.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => {setNewNotebookMode(true); setDoSearchMode(false);}} />
              </label>
            </ListItem>
            <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label htmlFor="contained-button-file-image-browse">
                <Avatar title="Load Notebooks" component="span" variant="rounded" alt="Load Notebook" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes/notes_load.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => loadNotebooksFnc()} />
              </label>
            </ListItem>
            <ListItem disabled={openedNoteNum !== null ? false : true} button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label htmlFor="contained-button-file-image-browse">
                <Avatar title="Save Notebook" component="span" variant="rounded" alt="Save Notebook" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes/notes_save.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => goToCreateMvs()} />
              </label>
            </ListItem>
            <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label>
                <Avatar title="Bookmark" component="span" variant="rounded" alt="Bookmark" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes/notes_bookmark.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={openBookmarkMenu} />
              </label>
            </ListItem>
            <div>
              <StyledMenu              
                id="font-size-menu"
                MenuListProps={{
                  'aria-labelledby': 'font-size-menu',
                }}                
                anchorEl={anchorBookmarkEl}
                open={openBookmark}
                onClose={closeBookmarkMenu}
              >
                {(topicId !== null && (!bookmarkInUse.trueBookmark || bookmarkInUse.trueBookmark === false)) &&
                  <MenuItem onClick={() => addBookmarkFnc(true)} style={{display: 'block'}}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes/notes_add_bookmark.png)'}}></span>
                    Add Bookmark
                    {(isPending && clickedBookmarkMenu === 'add') && <LinearProgress className={classes.menu_linear_progess} /> }
                  </MenuItem>
                }
                {bookmarkInUse.trueBookmark &&
                  <MenuItem onClick={() => getBookmarkFnc(true, 'clickload', 'load')}  style={{display: 'block'}}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes/notes_bookmark.png)'}}></span>
                    Load Bookmark                    
                    {(isPending && clickedBookmarkMenu === 'load') && <LinearProgress className={classes.menu_linear_progess} /> }
                  </MenuItem>
                  }
                  {(topicId !== null && bookmarkInUse.trueBookmark) &&
                  <MenuItem onClick={() => updateBookmarkFnc(true, page)} style={{display: 'block'}}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes/notes_update_bookmark.png)'}}></span>
                    Update Bookmark
                    {(isPending && clickedBookmarkMenu === 'update') && <LinearProgress className={classes.menu_linear_progess} /> }
                  </MenuItem>                 
                }
                {bookmarkInUse.trueBookmark &&
                  <MenuItem onClick={() => deleteBookmarkFnc(true)} style={{display: 'block'}}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes/notes_delete_bookmark.png)'}}></span>
                    Delete Bookmark
                    {(isPending && clickedBookmarkMenu === 'delete') && <LinearProgress className={classes.menu_linear_progess} /> }
                  </MenuItem>
                }
                {bookmarkInUse.lastOpened &&
                  <MenuItem onClick={() => getBookmarkFnc(false, 'clickload', 'loadlastopened')} style={{display: 'block'}}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes/notes_last_opened.png)'}}></span>
                    Load Last Opened Page
                    {(isPending && clickedBookmarkMenu === 'loadlastopened') && <LinearProgress className={classes.menu_linear_progess} /> }
                  </MenuItem>
                }
              </StyledMenu>
            </div>
            {notesMode === 0 &&
            <ListItem disabled={openedNoteNum !== null ? false : true} button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label htmlFor="contained-button-file-image-browse">
                <Avatar title="Edit Mode" component="span" variant="rounded" alt="Edit Mode" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes/notes_edit_mode.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => setNotesMode(1)} />
              </label>
            </ListItem>
            }
            {notesMode === 2 &&
            <ListItem disabled={openedNoteNum !== null ? false : true} button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label htmlFor="contained-button-file-image-browse">
                <Avatar title="View Mode" component="span" variant="rounded" alt="View Mode" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes/notes_view_mode.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => {setNotesMode(0); setTopicEditModeId(''); setContentEditModeId('');}} />
              </label>
            </ListItem>
            }
            {notesMode === 1 &&
            <ListItem disabled={openedNoteNum !== null ? false : true} button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label htmlFor="contained-button-file-image-browse">
                <Avatar title="Link Mode" component="span" variant="rounded" alt="Link Mode" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes/notes_link_mode.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => {setNotesMode(2); setTopicEditModeId(''); setContentEditModeId('');}} />
              </label>
            </ListItem>
            }          
            <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label htmlFor="contained-button-file-image-browse">
                <Avatar title="Search Notes" component="span" variant="rounded" alt="Search Notes" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes/notes_search.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => {searchModeFnc()}} />
              </label>
            </ListItem>
            <ListItem disabled={openedNoteNum !== null ? false : true} button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label>
              <Badge badgeContent={contentFontSize} color="primary" className={classes.font_badge}>
                <Avatar title="Font Size" component="span" variant="rounded" alt="Font Size" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes/notes_font_reset.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={openFontSizeMenu} />
                </Badge>
              </label>                    
            </ListItem>
            <div>
              <StyledMenu
                id="font-size-menu"
                MenuListProps={{
                  'aria-labelledby': 'font-size-menu',
                }}
                anchorEl={anchorFontSizeEl}
                open={openFontSize}
                onClose={closeFontSizeMenu}
              >
                <MenuItem onClick={() => setContentFontSize(16)}>                
                  <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes/notes_font_reset.png)'}}></span>
                  Reset Font Size                  
                </MenuItem>
                <MenuItem onClick={() => {contentFontSize < 34 && setContentFontSize(contentFontSize + 2)}}>
                  <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes/notes_font_up.png)'}}></span>
                  Increase Font Size
                </MenuItem>
                <MenuItem onClick={() => {contentFontSize > 8 && setContentFontSize(contentFontSize - 2)}}>
                  <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/notes/notes_font_down.png)'}}></span>
                  Decrease Font Size
                </MenuItem>
              </StyledMenu>
            </div>
          </List>
        </div>
      </DialogActions>
      }
      <DialogContent className={classes.dialog_content}>
        <Box style={{ margin: '8px'}}> 
          <Grid container align="center">
            <Grid container spacing={0} align="center" justify="center">            
              <Grid item xs={12} sm={10} md={6} lg={4} xl={3} style={{padding: '0px 0px'}}>
                {newNotebookMode &&
                  <Box style={{height: '100px'}}>
                    <TextField
                      label="New Notebook Name"
                      type="text"
                      variant="standard"
                      className={classes.text_box}
                      onChange={(e) => setNewNotebookName(e.target.value)}
                    />
                    <IconButton disabled={newNotebookName.trim().length > 0 ? false : true} title="Create Notebook" size="small" aria-label="directions" style={{float:"left", margin: '6px 30px 0 0'}} onClick={() => goToCreateNotebook()}>
                      <CheckIcon className={classes.iconbutton_content} />
                    </IconButton>
                    <IconButton title="Cancel" size="small" aria-label="directions" style={{float:"left", margin: '6px 0 0 0', }} onClick={() => setNewNotebookMode(false)}>
                      <ClearIcon className={classes.iconbutton_content} />
                    </IconButton>
                  </Box>
                }
                {doSearchMode &&
                  <Box style={{height: '100px', position: 'relative'}} > 
                    <TextField
                      id="searchField"
                      label="Search..."
                      type="text"
                      variant="standard"                  
                      className={classes.text_box_icon}                      
                      onChange={(e) => noteSearch(e.target.value)}
                    />
                    <i className={classes.search_icon}><SearchIcon /></i>
                    <IconButton title="Search" size="small" aria-label="directions" style={{float:"left", margin: '6px 30px 0 0'}} onClick={() => noteSearch(document.getElementById('searchField').value)}>
                      <SearchIcon className={classes.iconbutton_content} />
                      </IconButton>
                      <IconButton title="Cancel" size="small" aria-label="directions" style={{float:"left", margin: '6px 0 0 0', }} onClick={() => {searchModeFnc()}}>
                      <ClearIcon className={classes.iconbutton_content} />
                    </IconButton>
                  </Box>
                }
                {
                  notes.map((data, key) => {
                    return (
                      <div key={key+1}>{data}
                        <Box style={{
                          background: '#f7f4b5',         
                          display:'flex',
                          justifyContent:'center',
                          alignItems:'center',
                          borderRadius: '0 0 8px 8px',
                          paddingBottom: '8px',
                          boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                        }}>
                          <Pagination
                            count={Math.ceil(parseInt(allInfo[0]?.contents[openedNoteNum]?.length)/rowCount)}
                            size="medium"
                            page={page}
                            variant="outlined"
                            shape="rounded"                           
                            onChange={paginationChange}
                            style={{visibility: key === openedNoteNum ? 'visible': 'hidden'}}
                          />
                        </Box>
                      </div>
                    )
                  })
                }
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  </>
)
}
 
export default NotesApp;
