export const deletebeautysalonusersSrv = (param) => {
  async function deletebeautysalonusersService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/deletebeautysalonusers', {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: param.email
      }),
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return deletebeautysalonusersService()
}
