import React, {useLayoutEffect, useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CancelSharpIcon from '@material-ui/icons/Close';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import BorderInnerIcon from '@material-ui/icons/BorderInner';
import { makeStyles} from '@material-ui/core/styles';
import { themeHlp }  from '../Helper/ThemeHelper.js';
import  appResizeWindowHlp  from '../Helper/AppResizeWindowHelper.js';
import { searchfilesSrv } from '../Service/SearchFilesService.js';
import { searchsharedfilesSrv } from '../Service/SearchSharedFilesService.js';
import { getsharedfilesSrv } from '../Service/GetSharedFilesService.js';
import ImageViewerApp from '../Apps/ImageViewerApp';
import MediaPlayerApp from '../Apps/MediaPlayerApp';
import DocumentReaderApp from '../Apps/DocumentReaderApp';
import ArchiveReaderApp from '../Apps/ArchiveReaderApp';
import CodeBuilderApp from '../Apps/CodeBuilderApp';
import LinkStorageApp from '../Apps/LinkStorageApp';

const useStyles = makeStyles((theme) => ({
  menu_box:{
    width:'100%', 
    height:'340px', 
    margin:'auto auto', 
    background: 'linear-gradient(135deg, rgb(38, 93, 140) 0%, rgb(38, 93, 140) 15%, rgb(0, 140, 183) 50%)', 
    opacity:'1', 
    borderRadius:'15px', 
    border:'4px solid #f5a33e'
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: themeHlp().palette.secondary.light,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  user_avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: 'auto',
    cursor: 'pointer'
  },
  dialog_content: {
    padding: '0px'    
  },
  text_box: {
    width:'100%',
    margin: '4px 0',    
    borderBottom: '1px solid ' + themeHlp().palette.blackText.light,    
    fontSize: '20px', 
    '& .MuiInputLabel-root': {
      color: themeHlp().palette.blackText.light,
    },
    '& .MuiInputLabel-shrink': {
      color: themeHlp().palette.primary.light,      
    },
    '&:hover': {
      border: '1px solid ' + themeHlp().palette.primary.light,
      borderStyle: 'none none solid none',
    }
  },
  text_box_icon: {
    width:'100%',
    margin: '4px 0',    
    borderBottom: '1px solid ' + themeHlp().palette.blackText.light,    
    padding: '0px 24px',
    fontSize: '20px', 
    '& .MuiInputLabel-root': {
      color: themeHlp().palette.blackText.light,
      padding: '0px 24px',
    },
    '& .MuiInputLabel-shrink': {
      color: themeHlp().palette.primary.light,      
    },
    '&:hover': {
      border: '1px solid ' + themeHlp().palette.primary.light,
      borderStyle: 'none none solid none',
    }
  },
  iconbutton: {
    width:'36px',
    height: '36px',
    color: themeHlp().palette.primary.light,
    '&:hover' : {
      color: themeHlp().palette.primary.main
    },
    '&:active' : {
      color: themeHlp().palette.primary.dark,    
    }
  },
  input_label: {
    color: themeHlp().palette.primary.light,
    '& .MuiInputLabel-root': {
      color: 'yellow',
    },
    '& .MuiFormLabel-filled': {
      color: 'red',      
    },
  },
  search_icon: {
    color: themeHlp().palette.blackText.light + '!important',
    position:'absolute', 
    top: '24px', 
    left: '0px'
  },
  files_row: {
    width: "100%",
    height: '30px',

    '&:nth-child(even)': {
      backgroundColor: themeHlp().palette.secondary.main,
    },
    '&:nth-child(odd)': {
      backgroundColor: themeHlp().palette.secondary.dark,
    }
  },
  upload_file: {
    display: 'none'
  }
}));

const FileFinderApp = (props) => {

  const classes = useStyles();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [open, setOpen] = React.useState(false);
  const [usersFiles, setUsersFiles] = useState([]);
  const [mySharedFiles, setMySharedFiles] = useState([]);
  const [userSharedFiles, setUserSharedFiles] = useState([]);
  const [toolbarHidden, setToolbarHidden] = useState(true);
  let debounceSearching;

  const [imageViewerAppOn, setImageViewerAppOn] = useState(false);
  const [mediaPlayerAppOn, setMediaPlayerAppOn] = useState(false);
  const [documentReaderAppOn, setDocumentReaderAppOn] = useState(false);
  const [archiveReaderAppOn, setArchiveReaderAppOn] = useState(false);
  const [codeBuilderAppOn, setCodeBuilderAppOn] = useState(false);
  const [linkStorageAppOn, setLinkStorageAppOn] = useState(false);
  const filesExtensions = [
    'desktop',
    'folder',
    'avif',
    'ico',
    'cur',
    'bmp',
    'gif',
    'png',
    'jpeg',
    'jpg',
    'svg',
    'webp',
    'aac',
    'm4a',
    'mp3',
    'oga',
    'ogg',
    'opus',
    '3gp',
    'wav',
    'mkv',
    'mov',
    'mp4',             
    'webm',
    'txt', 
    'log',
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'odt',
    'ott',
    'ods',
    'ots',
    'odp',
    'otp',
    'rtf',
    'mvsa',
    'mvsls',
    'mvsc',
    'mvsl',
    'html',
    'htm',
    'css', 
    'js', 
    'jsx',
    'json',
    '7z',
    'gz',
    'gzip',
    'rar',
    'tar',
    'zip'
  ];
  const [fileInfo, setFileInfo] = useState(false);
  const [appCloses, setAppCloses] = useState(false);
  const [searchCategory, setSearchCategory] = useState(1);  
  const titleDisplaySize = 534;

  const searchCategoryChange = (event) => {
    setSearchCategory(event.target.value);
  };

  const choosedSearchCategory = (value) => {
    const getsharedfilesParams = {item_id: ''};
      if(value === 2){ getsharedfilesParams.item_id = 'fromme'}
      else if(value === 3){ getsharedfilesParams.item_id = 'tome'}
      if(value === 2 || value === 3){
        getsharedfilesSrv(getsharedfilesParams)
        .then(response => {
          if(value === 2){
            foundFrommeSharedFiles(response);
          }else if(value === 3){        
            foundTomeSharedFiles(response);        
          }
        }).catch(err => {
          console.log(err)
        //  history.push("/desktop");
        })
      }
  }

  const handleClickOpen = () => {
 
    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('filefinder') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount < 2){
      allApps.push('filefinder');
      setOpen(true);
      
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
        apps: allApps,
        appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount + 1
      }));

      setTimeout(() => {
        appsInWindows('load');
      },100);
    }
  };

  const handleClose = () => {
    setOpen(false);   

    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;
    if(allApps.indexOf('filefinder') !== -1){
      allApps.splice(allApps.indexOf('filefinder'), 1);
    }

    localStorage.setItem('movwin', JSON.stringify({
      windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
      apps: allApps,
      appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount - 1
    }));
  };

  const appsInWindows = (event) => {
    if(event === 'change'){
      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode < 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode + 1,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }else if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode === 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: 0,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }

      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).appCount === 0){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: 1
        }));
      }
    }

    setWindowWidth(windowWidth - 1);
    appResizeWindowHlp('fileFinderDialogBox');
  }

  const goToLocation = (location) => {
    window.location.assign(process.env.REACT_APP_URL_CLIENTPORT + location);
  }

  const searchFiles = (event) => {
    const searchfilesParams = {
      keyword: event.target.value
    }

    searchfilesSrv(searchfilesParams)
    .then(response => {
      setUsersFiles([]);
          
      for(let i=0; i<response.data.length; i++){
        setUsersFiles(oldUsersFiles => [...oldUsersFiles,
          <li key={i+1} className={classes.files_row}>
            <div title={response.data[i].location + '/' + response.data[i].name}>
              <Avatar title="File Location" component="span" variant="rounded" alt="File Location" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/filefinder.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} 
                onClick={() => goToLocation(response.data[i].location)}
              />
              {response.data[i].extension.toLowerCase() !== 'folder' && response.data[i].extension.toLowerCase() !== 'mvsl' &&
                <Link href={process.env.REACT_APP_URL_SERVERPORT + '/' + JSON.parse(localStorage.getItem('movacc')).account_id + response.data[i].location + '/' + response.data[i].realName} underline='none' style={{color:'white'}} target='_blank' download>
                  <Avatar title='Download' component="span" variant="rounded" alt="Download" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/download.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} />
                </Link>
              }
              <img alt={response.data[i].name} src={filesExtensions.find(element => element === response.data[i].extension.toLowerCase()) ? process.env.REACT_APP_URL_CLIENTPORT + '/icon/type/' + response.data[i].extension.toLowerCase() + '_file.png' : process.env.REACT_APP_URL_CLIENTPORT + '/icon/type/unknown_file.png'} style ={{width:"30px", float:"left", marginRight: "10px"}}/>
              {((
                  response.data[i].extension.toLowerCase() === 'avif' || 
                  response.data[i].extension.toLowerCase() === 'ico' || 
                  response.data[i].extension.toLowerCase() === 'cur' || 
                  response.data[i].extension.toLowerCase() === 'bmp' || 
                  response.data[i].extension.toLowerCase() === 'gif' || 
                  response.data[i].extension.toLowerCase() === 'png' || 
                  response.data[i].extension.toLowerCase() === 'jpeg' ||   
                  response.data[i].extension.toLowerCase() === 'jpg' || 
                  response.data[i].extension.toLowerCase() === 'svg' || 
                  response.data[i].extension.toLowerCase() === 'webp') && !imageViewerAppOn) &&          
                <>
                  <Avatar title='Image Viewer' component="span" variant="rounded" alt="Image Viewer" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/imageviewer.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}}
                    onClick={() => {                    
                      setFileInfo({
                        item_coords: response.data[i].coords,
                        item_ext: response.data[i].extension,
                        item_id: response.data[i].caseId,
                        item_location: response.data[i].location,
                        item_location_name: response.data[i].location + '/' + response.data[i].name,
                        name: response.data[i].name,
                      }); 
                      setImageViewerAppOn(true);
                    }} 
                  />                   
                </>  
              }
              {((
                response.data[i].extension.toLowerCase() === 'aac' || 
                response.data[i].extension.toLowerCase() === 'm4a' || 
                response.data[i].extension.toLowerCase() === 'mp3' || 
                response.data[i].extension.toLowerCase() === 'oga' || 
                response.data[i].extension.toLowerCase() === 'ogg' || 
                response.data[i].extension.toLowerCase() === 'opus' || 
                response.data[i].extension.toLowerCase() === '3gp' || 
                response.data[i].extension.toLowerCase() === 'wav' || 
                response.data[i].extension.toLowerCase() === 'mkv' || 
                response.data[i].extension.toLowerCase() === 'mov' || 
                response.data[i].extension.toLowerCase() === 'mp4' || 
                response.data[i].extension.toLowerCase() === 'webm') && !mediaPlayerAppOn) &&
                <>
                  <Avatar title='Media Player' component="span" variant="rounded" alt="Media Player" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} 
                    onClick={() => {
                      setFileInfo({
                        item_coords: response.data[i].coords,
                        item_ext: response.data[i].extension,
                        item_id: response.data[i].caseId,
                        item_location: response.data[i].location,
                        item_location_name: response.data[i].location + '/' + response.data[i].name,
                        name: response.data[i].name,
                      }); 
                      setMediaPlayerAppOn(true);
                    }} 
                  />            
                </>  
              }
              {((
                response.data[i].extension.toLowerCase() === 'txt' || 
                response.data[i].extension.toLowerCase() === 'log' || 
                response.data[i].extension.toLowerCase() === 'pdf' || 
                response.data[i].extension.toLowerCase() === 'doc' || 
                response.data[i].extension.toLowerCase() === 'docx' ||           
                response.data[i].extension.toLowerCase() === 'xls' || 
                response.data[i].extension.toLowerCase() === 'xlsx' || 
                response.data[i].extension.toLowerCase() === 'ppt' || 
                response.data[i].extension.toLowerCase() === 'pptx' || 
                response.data[i].extension.toLowerCase() === 'odt' ||           
                response.data[i].extension.toLowerCase() === 'ott' || 
                response.data[i].extension.toLowerCase() === 'ods' || 
                response.data[i].extension.toLowerCase() === 'ots' || 
                response.data[i].extension.toLowerCase() === 'odp' || 
                response.data[i].extension.toLowerCase() === 'otp' ||           
                response.data[i].extension.toLowerCase() === 'rtf' ||
                response.data[i].extension.toLowerCase() === 'mvsa' || 
                response.data[i].extension.toLowerCase() === 'html' || 
                response.data[i].extension.toLowerCase() === 'htm' || 
                response.data[i].extension.toLowerCase() === 'css' || 
                response.data[i].extension.toLowerCase() === 'json' || 
                response.data[i].extension.toLowerCase() === 'js' || 
                response.data[i].extension.toLowerCase() === 'jsx') && !documentReaderAppOn) &&          
                <>
                  <Avatar title='Document Reader' component="span" variant="rounded" alt="Document Reader" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/documentreader.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} 
                    onClick={() => {
                      setFileInfo({
                        item_coords: response.data[i].coords,
                        item_ext: response.data[i].extension,
                        item_id: response.data[i].caseId,
                        item_location: response.data[i].location,
                        item_location_name: response.data[i].location + '/' + response.data[i].name,
                        name: response.data[i].name,
                      }); 
                      setDocumentReaderAppOn(true);
                    }} 
                  />            
                </>  
              }
              {(
                response.data[i].extension.toLowerCase() === 'zip' && !archiveReaderAppOn) &&          
                <>
                  <Avatar title='Archive Reader' component="span" variant="rounded" alt="Archive Reader" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/archivereader.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} 
                    onClick={() => {
                      setFileInfo({
                        item_coords: response.data[i].coords,
                        item_ext: response.data[i].extension,
                        item_id: response.data[i].caseId,
                        item_location: response.data[i].location,
                        item_location_name: response.data[i].location + '/' + response.data[i].name,
                        name: response.data[i].name,
                      }); 
                      setArchiveReaderAppOn(true);
                    }} 
                  />            
                </>  
              }
              {(
                response.data[i].extension.toLowerCase() === 'mvsc' && !archiveReaderAppOn) &&          
                <>
                  <Avatar title='Code Builder' component="span" variant="rounded" alt="Code Builder" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} 
                    onClick={() => {
                      setFileInfo({
                        item_coords: response.data[i].coords,
                        item_ext: response.data[i].extension,
                        item_id: response.data[i].caseId,
                        item_location: response.data[i].location,
                        item_location_name: response.data[i].location + '/' + response.data[i].name,
                        name: response.data[i].name,
                      }); 
                      setCodeBuilderAppOn(true);
                    }} 
                  />            
                </>  
              }
                            {(
                response.data[i].extension.toLowerCase() === 'mvsls' && !linkStorageAppOn) &&          
                <>
                  <Avatar title='Link Storage' component="span" variant="rounded" alt="Link Storage" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/linkstorage.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} 
                    onClick={() => {
                      setFileInfo({
                        item_coords: response.data[i].coords,
                        item_ext: response.data[i].extension,
                        item_id: response.data[i].caseId,
                        item_location: response.data[i].location,
                        item_location_name: response.data[i].location + '/' + response.data[i].name,
                        name: response.data[i].name,
                      }); 
                      setLinkStorageAppOn(true);
                    }} 
                  />            
                </>  
              }
              <div style={{whiteSpace: 'nowrap'}}>
                <Typography style={{ fontWeight:'bold', color: themeHlp().palette.primary.dark, lineHeight: '2', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                  {response.data[i].location + '/' + response.data[i].name}  
                </Typography>
              </div>  
            </div>
          </li>
        ]);
      }
    }).catch(err => {
       console.log(err)
     //  history.push("/desktop");
    })
  }

  const fileSearch = (event) => {
    if(event.target.value !== ''){
      if(debounceSearching !== undefined){
        clearTimeout(debounceSearching);
      }
      debounceSearching = setTimeout(function() {       
        searchFiles(event);
      },800);    
    }else {      
      setUsersFiles([]);
    }
  }





/*Shared From Me Files Part*/
const foundFrommeSharedFiles = (response) => {
  setMySharedFiles([]);
  for(let i=0; i<response.data.length; i++){
    setMySharedFiles(oldMySharedFiles => [...oldMySharedFiles,
      <li key={i+1} className={classes.files_row}>
        <div title={response.data[i].location + '/' + response.data[i].name}>
          <Avatar title="File Location" component="span" variant="rounded" alt="File Location" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/filefinder.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} 
            onClick={() => goToLocation(response.data[i].location)}
          />
          <img alt={response.data[i].name} src={filesExtensions.find(element => element === response.data[i].extension.toLowerCase()) ? process.env.REACT_APP_URL_CLIENTPORT + '/icon/type/' + response.data[i].extension.toLowerCase() + '_file.png' : process.env.REACT_APP_URL_CLIENTPORT + '/icon/type/unknown_file.png'} style ={{width:"30px", float:"left", marginRight: "10px"}}/>
          {response.data[i].extension.toLowerCase() !== 'folder' && response.data[i].extension.toLowerCase() !== 'mvsl' &&
            <Link href={process.env.REACT_APP_URL_SERVERPORT + '/' + JSON.parse(localStorage.getItem('movacc')).account_id + response.data[i].location + '/' + response.data[i].realName} underline='none' style={{color:'white'}} target='_blank' download>
              <Avatar title='Download' component="span" variant="rounded" alt="Download" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/download.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} />
            </Link>
          }
          {((
            response.data[i].extension.toLowerCase() === 'avif' || 
            response.data[i].extension.toLowerCase() === 'ico' || 
            response.data[i].extension.toLowerCase() === 'cur' || 
            response.data[i].extension.toLowerCase() === 'bmp' || 
            response.data[i].extension.toLowerCase() === 'gif' || 
            response.data[i].extension.toLowerCase() === 'png' || 
            response.data[i].extension.toLowerCase() === 'jpeg' ||   
            response.data[i].extension.toLowerCase() === 'jpg' || 
            response.data[i].extension.toLowerCase() === 'svg' || 
            response.data[i].extension.toLowerCase() === 'webp') && !imageViewerAppOn) &&         
            <>
              <Avatar title='Image Viewer' component="span" variant="rounded" alt="Image Viewer" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/imageviewer.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}}
                onClick={() => {
                  setFileInfo({
                    item_ext: response.data[i].extension,
                    item_id: response.data[i].caseId,
                    item_location: response.data[i].location,
                    item_location_name: response.data[i].location + '/' + response.data[i].name,
                    name: response.data[i].name,
                  }); 
                  setImageViewerAppOn(true);
                }} 
              />                   
            </>  
          }
          {((
              response.data[i].extension.toLowerCase() === 'aac' || 
              response.data[i].extension.toLowerCase() === 'm4a' || 
              response.data[i].extension.toLowerCase() === 'mp3' || 
              response.data[i].extension.toLowerCase() === 'oga' || 
              response.data[i].extension.toLowerCase() === 'ogg' || 
              response.data[i].extension.toLowerCase() === 'opus' || 
              response.data[i].extension.toLowerCase() === '3gp' || 
              response.data[i].extension.toLowerCase() === 'wav' || 
              response.data[i].extension.toLowerCase() === 'mkv' || 
              response.data[i].extension.toLowerCase() === 'mov' || 
              response.data[i].extension.toLowerCase() === 'mp4' || 
              response.data[i].extension.toLowerCase() === 'webm') && !mediaPlayerAppOn) &&          
            <>
              <Avatar title='Media Player' component="span" variant="rounded" alt="Media Player" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} 
                onClick={() => {
                  setFileInfo({
                    item_ext: response.data[i].extension,
                    item_id: response.data[i].caseId,
                    item_location: response.data[i].location,
                    item_location_name: response.data[i].location + '/' + response.data[i].name,
                    name: response.data[i].name,
                  }); 
                  setMediaPlayerAppOn(true);
                }} 
              />            
            </>  
          }
          {((
              response.data[i].extension.toLowerCase() === 'txt' || 
              response.data[i].extension.toLowerCase() === 'log' || 
              response.data[i].extension.toLowerCase() === 'pdf' || 
              response.data[i].extension.toLowerCase() === 'doc' || 
              response.data[i].extension.toLowerCase() === 'docx' ||           
              response.data[i].extension.toLowerCase() === 'xls' || 
              response.data[i].extension.toLowerCase() === 'xlsx' || 
              response.data[i].extension.toLowerCase() === 'ppt' || 
              response.data[i].extension.toLowerCase() === 'pptx' || 
              response.data[i].extension.toLowerCase() === 'odt' ||           
              response.data[i].extension.toLowerCase() === 'ott' || 
              response.data[i].extension.toLowerCase() === 'ods' || 
              response.data[i].extension.toLowerCase() === 'ots' || 
              response.data[i].extension.toLowerCase() === 'odp' || 
              response.data[i].extension.toLowerCase() === 'otp' ||           
              response.data[i].extension.toLowerCase() === 'rtf' ||  
              response.data[i].extension.toLowerCase() === 'mvsa' || 
              response.data[i].extension.toLowerCase() === 'html' || 
              response.data[i].extension.toLowerCase() === 'htm' || 
              response.data[i].extension.toLowerCase() === 'css' || 
              response.data[i].extension.toLowerCase() === 'json' || 
              response.data[i].extension.toLowerCase() === 'js' || 
              response.data[i].extension.toLowerCase() === 'jsx') && !documentReaderAppOn) &&
            <>
              <Avatar title='Document Reader' component="span" variant="rounded" alt="Document Reader" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/documentreader.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} 
                onClick={() => {
                  setFileInfo({
                    item_ext: response.data[i].extension,
                    item_id: response.data[i].caseId,
                    item_location: response.data[i].location,
                    item_location_name: response.data[i].location + '/' + response.data[i].name,
                    name: response.data[i].name,
                  }); 
                  setDocumentReaderAppOn(true);
                }} 
              />            
            </>  
          }
          {(
            response.data[i].extension.toLowerCase() === 'zip' && !archiveReaderAppOn) &&          
            <>
              <Avatar title='Archive Reader' component="span" variant="rounded" alt="Archive Reader" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/archivereader.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} 
                onClick={() => {
                  setFileInfo({
                    item_ext: response.data[i].extension,
                    item_id: response.data[i].caseId,
                    item_location: response.data[i].location,
                    item_location_name: response.data[i].location + '/' + response.data[i].name,
                    name: response.data[i].name,
                  }); 
                  setArchiveReaderAppOn(true);
                }} 
              />            
            </>  
          }
          {(
            response.data[i].extension.toLowerCase() === 'mvsc' && !codeBuilderAppOn) &&          
            <>
              <Avatar title='Code Builder' component="span" variant="rounded" alt="Code Builder" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} 
                onClick={() => {
                  setFileInfo({
                    item_ext: response.data[i].extension,
                    item_id: response.data[i].caseId,
                    item_location: response.data[i].location,
                    item_location_name: response.data[i].location + '/' + response.data[i].name,
                    name: response.data[i].name,
                  }); 
                  setCodeBuilderAppOn(true);
                }} 
              />            
            </>  
          }
          {(
            response.data[i].extension.toLowerCase() === 'mvsls' && !linkStorageAppOn) &&          
            <>
              <Avatar title='Link Storage' component="span" variant="rounded" alt="Link Storage" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/linkstorage.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} 
                onClick={() => {
                  setFileInfo({
                    item_ext: response.data[i].extension,
                    item_id: response.data[i].caseId,
                    item_location: response.data[i].location,
                    item_location_name: response.data[i].location + '/' + response.data[i].name,
                    name: response.data[i].name,
                  }); 
                  setLinkStorageAppOn(true);
                }} 
              />            
            </>  
          }


          <div style={{whiteSpace: 'nowrap'}}>
            <Typography style={{ fontWeight:'bold', color: themeHlp().palette.primary.dark, lineHeight: '2', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              {response.data[i].location + '/' + response.data[i].name}             
            </Typography>
          </div>            
        </div>
      </li>
    ]);
  }
} 

const searchFrommeSharedFiles = (event) => { 
  const searchsharedfilesParams = {
    keyword: event.target.value,
    own: true
  }
  searchsharedfilesSrv(searchsharedfilesParams)
  .then(response => {
    foundFrommeSharedFiles(response);
  }).catch(err => {
     console.log(err)
   //  history.push("/desktop");
  })
}
/*Shared From Me Files Part END*/

/*Shared To Me Files Part*/
  const foundTomeSharedFiles = (response) => {
    setUserSharedFiles([]);        
    for(let i=0; i<response.data.length; i++){     
      setUserSharedFiles(oldUserSharedFiles => [...oldUserSharedFiles,
        <li key={i+1} className={classes.files_row}>
          <div title={response.data[i].name}>
            <Link href={process.env.REACT_APP_URL_SERVERPORT + '/' + response.data[i].emailId._id + response.data[i].location + '/' + response.data[i].realName} underline='none' style={{color:'white'}} target='_blank' download>
              <Avatar title='Download' component="span" variant="rounded" alt="Download" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/download.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} />
            </Link>
            <img alt={response.data[i].name} src={filesExtensions.find(element => element === response.data[i].extension.toLowerCase()) ? process.env.REACT_APP_URL_CLIENTPORT + '/icon/type/' + response.data[i].extension.toLowerCase() + '_file.png' : process.env.REACT_APP_URL_CLIENTPORT + '/icon/type/unknown_file.png'} style ={{width:"30px", float:"left", marginRight: "10px"}}/>
            {((
              response.data[i].extension.toLowerCase() === 'avif' || 
              response.data[i].extension.toLowerCase() === 'ico' || 
              response.data[i].extension.toLowerCase() === 'cur' || 
              response.data[i].extension.toLowerCase() === 'bmp' || 
              response.data[i].extension.toLowerCase() === 'gif' || 
              response.data[i].extension.toLowerCase() === 'png' || 
              response.data[i].extension.toLowerCase() === 'jpeg' ||   
              response.data[i].extension.toLowerCase() === 'jpg' || 
              response.data[i].extension.toLowerCase() === 'svg' || 
              response.data[i].extension.toLowerCase() === 'webp') && !imageViewerAppOn) &&
              <>
                <Avatar title='Image Viewer' component="span" variant="rounded" alt="Image Viewer" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/imageviewer.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}}
                  onClick={() => {                    
                    setFileInfo({
                      item_ext: response.data[i].extension,
                      item_id: response.data[i].caseId,
                      item_location: response.data[i].location,
                      item_location_name: response.data[i].location + '/' + response.data[i].name,
                      name: response.data[i].name,
                      shared_with: response.data[i].sharedWith
                    }); 
                    setImageViewerAppOn(true);
                  }} 
                />                   
              </>  
            }
            {((
              response.data[i].extension.toLowerCase() === 'aac' || 
              response.data[i].extension.toLowerCase() === 'm4a' || 
              response.data[i].extension.toLowerCase() === 'mp3' || 
              response.data[i].extension.toLowerCase() === 'oga' || 
              response.data[i].extension.toLowerCase() === 'ogg' || 
              response.data[i].extension.toLowerCase() === 'opus' || 
              response.data[i].extension.toLowerCase() === '3gp' || 
              response.data[i].extension.toLowerCase() === 'wav' || 
              response.data[i].extension.toLowerCase() === 'mkv' || 
              response.data[i].extension.toLowerCase() === 'mov' || 
              response.data[i].extension.toLowerCase() === 'mp4' || 
              response.data[i].extension.toLowerCase() === 'webm') && !mediaPlayerAppOn) &&          
              <>
                <Avatar title='Media Player' component="span" variant="rounded" alt="Media Player" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} 
                  onClick={() => {
                    setFileInfo({
                      item_ext: response.data[i].extension,
                      item_id: response.data[i].caseId,
                      item_location: response.data[i].location,
                      item_location_name: response.data[i].location + '/' + response.data[i].name,
                      name: response.data[i].name,
                      shared_with: response.data[i].sharedWith
                    }); 
                    setMediaPlayerAppOn(true);
                  }} 
                />            
              </>  
            }
            {((
              response.data[i].extension.toLowerCase() === 'txt' || 
              response.data[i].extension.toLowerCase() === 'log' || 
              response.data[i].extension.toLowerCase() === 'pdf' || 
              response.data[i].extension.toLowerCase() === 'doc' || 
              response.data[i].extension.toLowerCase() === 'docx' ||           
              response.data[i].extension.toLowerCase() === 'xls' || 
              response.data[i].extension.toLowerCase() === 'xlsx' || 
              response.data[i].extension.toLowerCase() === 'ppt' || 
              response.data[i].extension.toLowerCase() === 'pptx' || 
              response.data[i].extension.toLowerCase() === 'odt' ||           
              response.data[i].extension.toLowerCase() === 'ott' || 
              response.data[i].extension.toLowerCase() === 'ods' || 
              response.data[i].extension.toLowerCase() === 'ots' || 
              response.data[i].extension.toLowerCase() === 'odp' || 
              response.data[i].extension.toLowerCase() === 'otp' ||           
              response.data[i].extension.toLowerCase() === 'rtf' ||   
              response.data[i].extension.toLowerCase() === 'mvsa' || 
              response.data[i].extension.toLowerCase() === 'html' || 
              response.data[i].extension.toLowerCase() === 'htm' || 
              response.data[i].extension.toLowerCase() === 'css' || 
              response.data[i].extension.toLowerCase() === 'json' || 
              response.data[i].extension.toLowerCase() === 'js' || 
              response.data[i].extension.toLowerCase() === 'jsx') && !documentReaderAppOn) &&
              <>
                <Avatar title='Document Reader' component="span" variant="rounded" alt="Document Reader" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/documentreader.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} 
                  onClick={() => {
                    setFileInfo({
                      item_ext: response.data[i].extension,
                      item_id: response.data[i].caseId,
                      item_location: response.data[i].location,
                      item_location_name: response.data[i].location + '/' + response.data[i].name,
                      name: response.data[i].name,
                      shared_with: response.data[i].sharedWith
                    }); 
                    setDocumentReaderAppOn(true);
                  }} 
                />            
              </>  
            }
            {(
              response.data[i].extension.toLowerCase() === 'mvsc' && !codeBuilderAppOn) &&
              <>
                <Avatar title='Code Builder' component="span" variant="rounded" alt="Code Builder" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} 
                  onClick={() => {
                    setFileInfo({
                      item_ext: response.data[i].extension,
                      item_id: response.data[i].caseId,
                      item_location: response.data[i].location,
                      item_location_name: response.data[i].location + '/' + response.data[i].name,
                      name: response.data[i].name,
                      shared_with: response.data[i].sharedWith
                    }); 
                    setCodeBuilderAppOn(true);
                  }} 
                />            
              </>  
            }
                        {(
              response.data[i].extension.toLowerCase() === 'mvsls' && !linkStorageAppOn) &&
              <>
                <Avatar title='Link Storage' component="span" variant="rounded" alt="Link Storage" style={{width: '24px', height: '24px', cursor: 'pointer', float: 'right', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/linkstorage.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '3px 6px 0 0'}} 
                  onClick={() => {
                    setFileInfo({
                      item_ext: response.data[i].extension,
                      item_id: response.data[i].caseId,
                      item_location: response.data[i].location,
                      item_location_name: response.data[i].location + '/' + response.data[i].name,
                      name: response.data[i].name,
                      shared_with: response.data[i].sharedWith
                    }); 
                    setLinkStorageAppOn(true);
                  }} 
                />            
              </>  
            }

            <div style={{whiteSpace: 'nowrap'}}>
              <Typography style={{ fontWeight:'bold', color: themeHlp().palette.primary.dark, lineHeight: '2', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                {response.data[i].name} - <span style={{fontSize: '12px'}}>{response.data[i].emailId.email}</span>                
              </Typography>
            </div>
          </div>
        </li>
      ]);
    }
  }

  const searchTomeSharedFiles = (event) => {
    const searchsharedfilesParams = {
      keyword: event.target.value,
      own: false
    }
    searchsharedfilesSrv(searchsharedfilesParams)
    .then(response => {
      foundTomeSharedFiles(response)
    }).catch(err => {
      console.log(err);
    })
  }
/*Shared To Me Files Part END*/

const mySharedFileSearch = (event) => {
    if(debounceSearching !== undefined){
      clearTimeout(debounceSearching);
    }
    debounceSearching = setTimeout(function() {
      searchFrommeSharedFiles(event);
    },800);
}

const userSharedFileSearch = (event) => {
    if(debounceSearching !== undefined){
      clearTimeout(debounceSearching);
    }
    debounceSearching = setTimeout(function() {
      searchTomeSharedFiles(event);
    },800);
}
/*Shared Files Part END*/

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);      
    }

    window.addEventListener('resize', updateSize);
    updateSize();
  
    return () => {     
      window.removeEventListener('resize', updateSize); 
    }
  },[windowWidth, windowHeight])

  useEffect(() => {
    
    if(props.fileInfo){
      setOpen(false);
      props.changePropertiesFnc('open', props.fileInfo.item_location_name);
      props.closeProperties(false);
    }

    if(appCloses === true){
      setImageViewerAppOn(false);
      setMediaPlayerAppOn(false);
      setDocumentReaderAppOn(false);
      setArchiveReaderAppOn(false);
      setCodeBuilderAppOn(false);
      setLinkStorageAppOn(false);
      setAppCloses(false);
    }

    if(!localStorage.getItem('movwin')){
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: 0,
        apps: [],
        appCount: 1
      }));
    } 

    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('filefinder') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount < 2){
      setOpen(true);
      setTimeout(() => {
        const muiBackdrop = document.querySelectorAll('.MuiBackdrop-root');
        let newApp = JSON.parse(localStorage.getItem('movwin')).apps;
        newApp.push('filefinder');

        if(muiBackdrop[2]){
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 2
          })); 
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1]) {
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 1
          }));
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1] === undefined) {
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: [],
            appCount: 0,
          }));
        }
        appsInWindows('load');
      },100);
    }

  },[appCloses])


return (
  <>
    <Avatar title="File Finder" component="span" variant="rounded" alt="File Finder" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/filefinder.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={handleClickOpen} />
    <Typography title="File Finder" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>File Finder</Typography>
    <Dialog id="fileFinderDialogBox" fullScreen onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        style={{
          padding: '8px 16px',
          color:'#ffffff',
          background:'linear-gradient(90deg, rgba(38,93,140,1) 0%, rgba(38,93,140,1) 15%, rgba(0,140,183,1) 100%)'
        }}
        id="customized-dialog-title" onClose={handleClose}
      >
        <Avatar component="span" variant="rounded" style={{width:'24px', height: '24px', display: 'inline-block', marginRight: '8px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/filefinder.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
        <span>{(windowWidth < titleDisplaySize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0) || (windowWidth < (titleDisplaySize/2) && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) === 0) ? '' : 'File Finder'}</span>
        <IconButton
          disabled={document.getElementById('shutdownDialog') ? false : true}
          aria-label="AppsInWindowsIcon"
          onClick={() => appsInWindows('change')}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white',          
            margin:'2px 42px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px',
            opacity: document.getElementById('shutdownDialog') ? '1' : '0.3'
          }}
        >
          <BorderInnerIcon style={{padding: '2px'}} />          
        </IconButton>
        <IconButton 
          aria-label="UnfoldLessMoreIcon"
          title={!toolbarHidden ? 'Hide Toolbar' : 'Show Toolbar'}
          onClick={()=> setToolbarHidden(current => !current)}
          size="small"
          style={{
            width: '18px',
            height: '18px',
            background: themeHlp().palette.blueGrey.dark,
            color:'white',  
            margin:'2px 74px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          {!toolbarHidden ? <UnfoldLessIcon style={{padding: '4px'}} /> : <UnfoldMoreIcon style={{padding: '4px'}} />}
        </IconButton>
        <IconButton 
          aria-label="CancelSharpIcon"
          title="Close"
          onClick={handleClose}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white', 
            margin:'2px 10px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          <CancelSharpIcon style={{padding: '2px'}} /> 
        </IconButton>
      </DialogTitle>
      <DialogActions id="p0" className={classes.dialog_content}>
        <div id="p1" className={classes.root}>
          <List style={{ width: '100%', height: toolbarHidden ? 0 : '64px', padding: '0px', overflowY: 'auto'}}>
            {
              <>
                <ListItem button style={{width: '64px', height: toolbarHidden ? 0 : '64px', float:'left', padding: toolbarHidden ? 0 : '12px 12px', visibility: 'hidden', display: 'inline-block', textIndent: '150px', overflow: 'hidden'}}>
                  <label htmlFor="contained-button-file-image-browse">
                    {imageViewerAppOn &&
                      <ImageViewerApp value={true} appCloses={(appCloses) => setAppCloses(appCloses)} fileInfo={fileInfo} />
                    }
                  </label>
                </ListItem>
                <ListItem button style={{width: '64px', height: toolbarHidden ? 0 : '64px', float:'left', padding: toolbarHidden ? 0 : '12px 12px', visibility: 'hidden', display: 'inline-block', textIndent: '150px', overflow: 'hidden'}}>
                  <label htmlFor="contained-button-file-image-browse">
                    {mediaPlayerAppOn &&
                      <MediaPlayerApp value={true} appCloses={(appCloses) => setAppCloses(appCloses)} fileInfo={fileInfo} />
                    }
                  </label>
                </ListItem>
                <ListItem button style={{width: '64px', height: toolbarHidden ? 0 : '64px', float:'left', padding: toolbarHidden ? 0 : '12px 12px', visibility: 'hidden', display: 'inline-block', textIndent: '150px', overflow: 'hidden'}}>
                  <label htmlFor="contained-button-file-image-browse">
                    {documentReaderAppOn &&
                      <DocumentReaderApp value={true} appCloses={(appCloses) => setAppCloses(appCloses)} fileInfo={fileInfo} />
                    }
                  </label>
                </ListItem>
                <ListItem button style={{width: '64px', height: toolbarHidden ? 0 : '64px', float:'left', padding: toolbarHidden ? 0 : '12px 12px', visibility: 'hidden', display: 'inline-block', textIndent: '150px', overflow: 'hidden'}}>
                  <label htmlFor="contained-button-file-image-browse">
                    {archiveReaderAppOn &&
                      <ArchiveReaderApp value={true} appCloses={(appCloses) => setAppCloses(appCloses)} fileInfo={fileInfo} />
                    }
                  </label>
                </ListItem>
                <ListItem button style={{width: '64px', height: toolbarHidden ? 0 : '64px', float:'left', padding: toolbarHidden ? 0 : '12px 12px', visibility: 'hidden', display: 'inline-block', textIndent: '150px', overflow: 'hidden'}}>
                  <label htmlFor="contained-button-file-image-browse">
                    {codeBuilderAppOn &&
                      <CodeBuilderApp value={true} appCloses={(appCloses) => setAppCloses(appCloses)} fileInfo={fileInfo} />
                    }
                  </label>
                </ListItem>
                <ListItem button style={{width: '64px', height: toolbarHidden ? 0 : '64px', float:'left', padding: toolbarHidden ? 0 : '12px 12px', visibility: 'hidden', display: 'inline-block', textIndent: '150px', overflow: 'hidden'}}>
                  <label htmlFor="contained-button-file-image-browse">
                    {linkStorageAppOn &&
                      <LinkStorageApp value={true} appCloses={(appCloses) => setAppCloses(appCloses)} fileInfo={fileInfo} />
                    }
                  </label>
                </ListItem>
              </> 
            }       
          </List>          
        </div>
      </DialogActions>
      <DialogContent>
        <Box sx={{ minWidth: 120 }}>          
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" className={classes.input_label}>Choose a search category</InputLabel>
            <Select
              labelId="demo-simple-select-label"    
              id="demo-simple-select"
              className={classes.text_box}
              value={searchCategory}
              label="Choose a search category"
              onChange={searchCategoryChange}
            >
              <MenuItem value={1} onClick={() => choosedSearchCategory(1)}>Find own files</MenuItem>
              <MenuItem value={2} onClick={() => choosedSearchCategory(2)}>Find own shared files</MenuItem>
              <MenuItem value={3} onClick={() => choosedSearchCategory(3)}>Find shared files addressed to me</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {searchCategory === 1 &&
            <div style={{position: 'relative', textAlign: 'center'}}>
              <TextField
                label="Search..."
                type="text"
                name="search"
                variant="standard"
                className={classes.text_box_icon}                
                onChange={fileSearch}
              />
              <i className={classes.search_icon}><SearchIcon /></i> 
              <nav
                style={{
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  width: '100%',
                  height: '482px',
                  textAlign: 'left',
                  marginTop: '30px'
                }}
              >
                <ul
                  style={{ listStyleType: 'none',
                    margin: 0,
                    padding: 0
                  }}
                >
                  {
                    usersFiles.map((data) => {
                      return data;
                    })
                  }                  
                </ul>
              </nav>
            </div>
          }
          {searchCategory === 2 &&
            <div style={{position: 'relative', textAlign: 'center'}}>
              <TextField
                label="Search..."
                type="text"
                name="search"
                variant="standard"
                className={classes.text_box_icon}                
                onChange={mySharedFileSearch}
              /> 
              <i className={classes.search_icon}><SearchIcon /></i> 
              <nav
                style={{
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  width: '100%',
                  height: '482px',
                  textAlign: 'left',
                  marginTop: '30px'
                }}
              >
                <ul
                  style={{ listStyleType: 'none',
                    margin: 0,
                    padding: 0
                  }}
                >
                  {
                    mySharedFiles.map((data) => {
                      return data;
                    })
                  }                  
                </ul>
              </nav>
            </div>
          }
          {searchCategory === 3 &&
            <div style={{position: 'relative', textAlign: 'center'}}>
              <TextField
                label="Search..."
                type="text"
                name="search"
                variant="standard"
                className={classes.text_box_icon}                
                onChange={userSharedFileSearch}
              /> 
              <i className={classes.search_icon}><SearchIcon /></i> 
              <nav
                style={{
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  width: '100%',
                  height: '482px',
                  textAlign: 'left',
                  marginTop: '30px'
                }}
              >
                <ul
                  style={{ listStyleType: 'none',
                    margin: 0,
                    padding: 0
                  }}
                >
                  {
                    userSharedFiles.map((data) => {
                      return data;
                    })
                  }                  
                </ul>
              </nav>
            </div>
          }
      </DialogContent>
    </Dialog>
  </>
);
}
 
export default FileFinderApp;
