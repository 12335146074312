export const calcbeautysalonclientstransactionsSrv = (param) => {
  async function calcbeautysalonclientstransactionsService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/calcbeautysalonclientstransactions', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: param?.user_id ? param?.user_id : null,
        appointmentStartDate: param.start_date_time,
        appointmentEndDate: param.end_date_time
      }),
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return calcbeautysalonclientstransactionsService()
}
